import React from "react";
import { Avatar, Box, MenuItem } from "@material-ui/core";
import Typography from "../../../components/Typography";
import { useStyles } from "./children.styles";

interface IProps {
  company: any;
  switchUser: (id: string) => void;
  handleClose: (event: React.MouseEvent<EventTarget>) => void;
  disabled: boolean;
}

const CompanyMenuItem = (props: IProps) => {
  const { company, handleClose, switchUser, disabled } = props;
  const classes = useStyles();

  return (
    <MenuItem
      key={company.id}
      disabled={disabled}
      onClick={e => {
        !disabled && switchUser(company.id);
        !disabled && handleClose(e);
      }}
      classes={{ root: classes.menuItem }}
    >
      <Box display="flex" alignItems="center" justifyContent="center">
        <Avatar
          classes={{
            root: classes.avatar,
            colorDefault: classes.textPrimary,
          }}
        >
          {company.name.charAt(0)}
        </Avatar>
        <Typography
          variant="subtitle1"
          color="textPrimary"
          className={classes.companyName}
        >
          {company.name}
        </Typography>
      </Box>
    </MenuItem>
  );
};

export default CompanyMenuItem;
