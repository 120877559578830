import React from "react";
import ImageElement from "./ImageElement";

const Element = props => {
  const { attributes, children, element } = props;
  const customStyles = { color: element.color, fontSize: element.fontSize };
  switch (element.type) {
    case "block-quote":
      return (
        <blockquote {...attributes} style={customStyles}>
          {children}
        </blockquote>
      );
    case "bulleted-list":
      return (
        <ul {...attributes} style={customStyles}>
          {children}
        </ul>
      );
    case "heading-one":
      return (
        <h1 {...attributes} style={customStyles}>
          {children}
        </h1>
      );
    case "heading-two":
      return (
        <h2 {...attributes} style={customStyles}>
          {children}
        </h2>
      );
    case "list-item":
      return (
        <li {...attributes} style={customStyles}>
          {children}
        </li>
      );
    case "numbered-list":
      return (
        <ol {...attributes} style={customStyles}>
          {children}
        </ol>
      );
    case "link":
      return (
        <table style={{ width: "fit-content" }} {...attributes}>
          <tbody>
            <tr>
              <td
                style={{
                  padding: "6px 25px",
                  boxShadow:
                    "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
                  fontFamily: "'DM Sans', 'Open Sans', sans-serif",
                  fontWeight: 500,
                  borderRadius: "2px",
                  backgroundColor: "#4646b4",
                  margin: "5px 0",
                  textAlign: "center",
                  color: "white",
                  height: "50px",
                }}
              >
                <a
                  style={{
                    textDecoration: "none",
                    color: "white",
                    fontSize: "16px",
                  }}
                  rel="noreferrer"
                  target="_blank"
                  href={element.url}
                >
                  {children}
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      );
    case "image":
      return <ImageElement {...props} />;
    default:
      return (
        <p {...attributes} style={customStyles}>
          {children}
        </p>
      );
  }
};

export default Element;
