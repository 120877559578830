import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  h7: {
    fontFamily: "DM Sans",
    fontSize: 14,
    fontWeight: 700,
  },
  subtitle3: {
    fontWeight: 500,
    fontFamily: "DM Sans",
    fontSize: 14,
  },
  subtitle4: {
    fontWeight: 500,
    fontFamily: "DM Sans",
    fontSize: 13,
  },
  body3: {
    fontSize: 14,
    fontWeight: 600,
    fontFamily: "Open Sans",
  },
  body4: {
    fontSize: 12,
    fontWeight: 400,
    fontFamily: "Open Sans",
  },
}));
