export const pagesTypes = [
  {
    type: "cpiIncreaseNotice",
    name: "content.cpi_increase_notice",
  },
  {
    type: "cpiDecreaseNotice",
    name: "content.cpi_decrease_notice",
  },
  // {
  //   type: "contactPage",
  //   name: "content.contact_page",
  // },
  // {
  //   type: "helpPage",
  //   name: "content.help_page",
  // },
  {
    type: "leaseCalculationPrinciple",
    name: "content.lease_calculation_principle",
  },
];

export const emailsTypes = [
  {
    type: "migrationOfSystem",
    name: "emails.migration_of_system",
  },
  {
    type: "passwordReset",
    name: "emails.password_reset",
  },
  {
    type: "invitationToSystem",
    name: "emails.invitation_to_system",
  },
  {
    type: "newInvoice",
    name: "emails.new_invoice",
  },
  {
    type: "turnoverReminder",
    name: "emails.turnover_reminder",
  },
  {
    type: "secondTurnoverReminder",
    name: "emails.second_turnover_reminder",
  },
];

export const contentTypeHelper = type => {
  if (
    type === "cpiIncreaseNotice" ||
    type === "cpiDecreaseNotice" ||
    type === "contactPage" ||
    type === "helpPage" ||
    type === "leaseCalculationPrinciple"
  )
    return "content";
  if (
    type === "newInvoice" ||
    type === "invitationToSystem" ||
    type === "passwordReset" ||
    type === "migrationOfSystem"
  )
    return "emails";
};
