import React from "react";
import {
  Dialog,
  Box,
  Typography,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import ReadOnlyEditor from "../ReadOnlyEditor/ReadOnlyEditor";
import CloseIcon from "@material-ui/icons/Close";
import { useStyles } from "./CpiChangesDialog.styles";
import { ICurrentPercentResponse } from "../../types/cpiTypes";
import { useTranslation } from "react-i18next";
import CurrentCpi from "../CurrentCpi";

interface IProps {
  isOpen: boolean;
  message: any;
  cpiInfo: ICurrentPercentResponse;
  confirmHandler: () => void;
}

const CpiChangesDialog = (props: IProps) => {
  const { isOpen, message, cpiInfo, confirmHandler } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog
      open={isOpen}
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="sm"
      fullWidth={true}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        className={classes.dialogTitle}
      >
        <Typography variant="h3">{t("fields.cpi_changes")}</Typography>
        <CloseIcon
          onClick={() => confirmHandler()}
          className={classes.closeIcon}
        />
      </Box>
      <DialogContent className={classes.dialogContent}>
        <ReadOnlyEditor message={message} />
        <Box marginTop={3} />
        <CurrentCpi
          cpiInfo={cpiInfo.currentCPI}
          padding="14px 20px"
          title="pages.new_cpi_information"
        />
        <Box marginTop={2} />
        <CurrentCpi
          cpiInfo={cpiInfo.previousCPI}
          padding="14px 20px"
          title="pages.current_cpi_information"
        />
      </DialogContent>
      <DialogActions className={classes.actionButtons}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => confirmHandler()}
          className={classes.submitBtn}
          style={{ textTransform: "capitalize" }}
        >
          {t("buttons.ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CpiChangesDialog;
