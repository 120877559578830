import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import Error from "../../components/Error";

interface IState {
  errorMessage: string;
}

class ErrorBoundary extends Component<WithTranslation, IState> {
  state = {
    errorMessage: "",
  };

  static getDerivedStateFromError(error) {
    return {
      errorMessage: error.response ? error.response.data.message : error,
    };
  }

  render() {
    const { errorMessage } = this.state;
    const { children, t } = this.props;
    if (errorMessage) {
      return (
        <Error
          title={t("error.something_went_wrong")}
          message={errorMessage}
          alignment="center"
          height="35vh"
          width="100%"
          marginTop="45px"
          bgcolor="white"
        />
      );
    }
    return children;
  }
}

export default withTranslation()(ErrorBoundary);
