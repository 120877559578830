import React, { createContext, useState, useContext } from "react";
import { INotification } from "../types/notificationTypes";

const notificationsContext = createContext<any>({
  showError: () => {},
  openError: () => {},
  list: [],
});

export const ProvideNotifications = ({ children }) => {
  const notifications = useProvideNotifications();
  return (
    <notificationsContext.Provider value={notifications}>
      {children}
    </notificationsContext.Provider>
  );
};

export const useNotifications = () => {
  return useContext(notificationsContext);
};

const useProvideNotifications = () => {
  const [list, setList] = useState<INotification[]>([]);
  let notificationProperties: INotification = null;

  const showSuccess = (
    title: string | React.ReactFragment,
    message: string | React.ReactFragment
  ) => {
    const id = Math.floor(Math.random() * 101 + 1);
    notificationProperties = {
      id,
      variant: "success",
      title,
      message,
    };
    setList([...list, notificationProperties]);
  };

  const showError = (
    title: string | React.ReactFragment,
    message: string | React.ReactFragment
  ) => {
    const id = Math.floor(Math.random() * 101 + 1);
    notificationProperties = {
      id,
      variant: "error",
      title,
      message,
    };
    setList([...list, notificationProperties]);
  };

  return {
    showSuccess,
    showError,
    list,
  };
};

export default useNotifications;
