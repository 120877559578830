import { IFields } from "../types/userTypes";
import { IPersonalDetailsFields } from "../types/accountTypes";

export const validateEmail = (email: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validateUsersFields = (fields: IFields) => {
  if (fields.name === "") return "name";
  else if (fields.email === "" || !validateEmail(fields.email)) return "email";
  else return "";
};

export const validateAccountFields = (fields: IPersonalDetailsFields) => {
  if (fields.name === "") return "name";
  else if (fields.email === "" || !validateEmail(fields.email)) return "email";
  else if (fields.position === "") return "position";
  else if (fields.phone.length <= 4) return "phone";
  else return "";
};
