import React, { Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import routes from "./RouteList";
import PrivateRoute from "./PrivateRoute";
import Loader from "../../components/Loader";

const Routes = () => {
  return (
    <Suspense
      fallback={<Loader width="100%" height="80vh" alignment="center" />}
    >
      <Switch>
        {routes.map((route, idx) => {
          return route.component && route.isPrivate ? (
            <PrivateRoute
              key={idx}
              path={route.path}
              exact={route.exact}
              routePermission={route.permissions}
              requiredRoles={route.role}
              component={props => (
                <route.component
                  {...props}
                  name={route.name}
                  items={route.items}
                />
              )}
            />
          ) : route.component ? (
            <Route
              key={idx}
              path={route.path}
              exact={route.exact}
              render={props => (
                <route.component
                  {...props}
                  name={route.name}
                  items={route.items}
                />
              )}
            />
          ) : null;
        })}
        <Redirect to="/" />
      </Switch>
    </Suspense>
  );
};

export default Routes;
