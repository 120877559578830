import React from "react";
import { Typography, Box } from "@material-ui/core";
import { useStyles } from "./Title.styles";
import { useTranslation } from "react-i18next";

interface IProps {
  name: string;
}

const Title = (props: IProps) => {
  const classes = useStyles();
  const { name } = props;
  const { t } = useTranslation();

  return (
    <Box className={classes.wrapper}>
      <Typography variant="h1" classes={{ h1: classes.heading }}>
        {t(`${name}`)}
      </Typography>
    </Box>
  );
};
export default Title;
