import React, { useState } from "react";
import { Box, Fade, IconButton, Paper, Typography } from "@material-ui/core";
import { useStyles } from "./Notifications.styles";
import ErrorIcon from "@material-ui/icons/Error";
import CheckIcon from "../../assets/icons/notification/checkmark.svg";
import { INotification } from "../../types/notificationTypes";
import { Close } from "@material-ui/icons";

interface IProps {
  notificationList: INotification[];
}

const Notifications = (props: IProps) => {
  const { notificationList } = props;
  const classes = useStyles();
  const [list, setList] = useState<INotification[]>(notificationList);

  React.useEffect(() => {
    setList([...notificationList]);
    // eslint-disable-next-line
  }, [notificationList]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (notificationList.length && list.length) {
        deleteToast(notificationList[0].id);
      }
    }, 5000);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, [notificationList, list]);

  const deleteToast = id => {
    const listItemIndex = list.findIndex(e => e.id === id);
    const toastListItem = notificationList.findIndex(e => e.id === id);
    list.splice(listItemIndex, 1);
    notificationList.splice(toastListItem, 1);
    setList([...list]);
  };

  return (
    <Box className={classes.container}>
      {list.map((toast, i) => (
        <Fade key={i} in={true} timeout={500}>
          <Paper elevation={4} square className={classes.notification}>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              padding={3}
              position="relative"
            >
              <Box position="absolute" top="3px" right="3px">
                <IconButton
                  onClick={() => deleteToast(toast.id)}
                  disableRipple
                  disableFocusRipple
                  size="small"
                >
                  <Close />
                </IconButton>
              </Box>
              <Box marginRight={2}>
                {toast.variant === "success" && (
                  <img alt="CheckIcon" src={CheckIcon} />
                )}
                {toast.variant === "error" && (
                  <ErrorIcon color="error" className={classes.icon} />
                )}
              </Box>
              <Box>
                <Typography variant="h4">{toast.title}</Typography>
                <Typography variant="body1">{toast.message}</Typography>
              </Box>
            </Box>
          </Paper>
        </Fade>
      ))}
    </Box>
  );
};

export default Notifications;
