import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;
const tokens = JSON.parse(localStorage.getItem("tokens"));
axios.defaults.baseURL = apiUrl;
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.common["Authorization"] = tokens
  ? `Bearer ${tokens.accessToken}`
  : "";

export const hasAuthHeader = () => {
  return axios.defaults.headers.common["Authorization"] !== "";
};

export const setCompanyIdHeader = (companyId: string) => {
  axios.defaults.headers.common["x-selected-company-id"] = companyId;
};

export const removeCompanyIdHeader = () => {
  delete axios.defaults.headers.common["x-selected-company-id"];
};

export const setAuthHeader = async (token: string) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  return;
};

const fetcher = url =>
  axios
    .get(url)
    .then(res => res.data)
    .catch(err => {
      throw new Error(err.response.data.message);
    });

export const mutateData = (
  type: "patch" | "delete" | "post" | "put" | "get",
  url: string,
  data?: any,
  headers?: {
    "Content-Type"?: string;
    "Access-Control-Allow-Origin"?: string;
  },
  responseType?: any
) => {
  return axios({
    method: type,
    url: url,
    data,
    headers,
    responseType: responseType,
  });
};

export const SWROptions = {
  fetcher,
  onError: (err, key, config) => {
    if (err && err.response) throw new Error(err.response.data.message);
  },
  onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
    return;
  },
};
