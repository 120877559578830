import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";
import { ReactComponent as ChevronDown } from "../assets/icons/basic/chevron-down.svg";

export default responsiveFontSizes(
  createMuiTheme({
    props: {
      MuiNativeSelect: {
        IconComponent: ChevronDown,
      },
      MuiSelect: {
        IconComponent: ChevronDown,
        onOpen: () => (document.body.style.overflow = "hidden"),
        onClose: () => (document.body.style.overflow = ""),
      },
      MuiInputLabel: {
        shrink: true,
      },
      MuiDialog: {
        onExited: () => (document.body.style.overflow = ""),
        onEntered: () => (document.body.style.overflow = "hidden"),
      },
    },
    palette: {
      primary: {
        main: "#4646B4",
        light: "#A4AAF5",
        dark: "#3030AE",
      },
      secondary: {
        main: "#FFFFFF",
      },
      background: {
        default: "#F2F4F9",
        paper: "#FFFFFF",
      },
      text: {
        primary: "#333333",
        secondary: "#808080",
      },
      grayScale: {
        light: "#F9F9F9",
        main: "#F2F4F9",
        dark: "#F2F2F2",
      },
      error: {
        main: "#FD4671",
        dark: "#F22253",
      },
      additional: {
        lightBlueDarker: "#8890F5",
        disabled: "#DADCE1",
        errorText: "#FFE6EC",
        errorBg: "#FFE6EC",
      },
    },
    typography: {
      fontFamily: "Open Sans",
      h1: {
        fontFamily: "DM Sans",
        fontSize: 48,
        fontWeight: 700,
      },
      h2: {
        fontFamily: "DM Sans",
        fontSize: 38,
        fontWeight: 700,
      },
      h3: {
        fontFamily: "DM Sans",
        fontSize: 24,
        fontWeight: 700,
      },
      h4: {
        fontFamily: "DM Sans",
        fontSize: 20,
        fontWeight: 700,
      },
      h5: {
        fontFamily: "DM Sans",
        fontSize: 18,
        fontWeight: 700,
      },
      h6: {
        fontFamily: "DM Sans",
        fontSize: 16,
        fontWeight: 700,
      },
      subtitle1: {
        fontWeight: 500,
        fontFamily: "DM Sans",
        fontSize: 18,
      },
      subtitle2: {
        fontWeight: 500,
        fontFamily: "DM Sans",
        fontSize: 16,
      },
      body1: {
        fontSize: 16,
        fontWeight: 400,
      },
      body2: {
        fontSize: 14,
        fontWeight: 400,
      },
      button: {
        textTransform: "none",
        fontSize: 16,
        fontFamily: "DM Sans",
        fontWeight: 500,
      },
    },
    overrides: {
      MuiChip: {
        root: {
          padding: "8.5px 12px",
          fontFamily: "DM Sans",
          fontSize: 14,
          fontWeight: 700,
          cursor: "pointer",
          backgroundColor: "#F2F4F9",
          "&:hover, &:focus": {
            background: "#DADCE1",
          },
        },
      },
      MuiInputAdornment: {
        root: {
          "&.MuiPhoneNumber-positionStart": {
            marginRight: 0,
          },
          "& .MuiPhoneNumber-flagButton": {
            minWidth: 50,
          },
        },
      },
      MuiFormHelperText: {
        root: {
          "&.Mui-error": {
            color: "#FF003C",
            height: "50px",
            background: "rgba(255,0,60,0.1)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minWidth: "238px",
            borderRadius: "2px",
          },
        },
      },
      MuiRadio: {
        colorPrimary: {
          "& .MuiSvgIcon-root": {
            color: "#4646B4",
          },
        },
      },
      MuiTypography: {
        gutterBottom: {
          marginBottom: "8px",
        },
      },
      MuiPaper: {
        rounded: {
          borderRadius: "10px",
        },
      },
      MuiDivider: {
        root: {
          marginTop: "5px",
          marginBottom: "5px",
          backgroundColor: "#F2F2F2",
        },
      },
      MuiInputLabel: {
        shrink: {
          transform: "none",
          marginBottom: "4px",
        },
      },
      MuiTableCell: {
        root: {
          borderBottom: "#F2F2F2 solid 1px",
          paddingTop: "12px",
          paddingBottom: "12px",
          "&:first-child": {
            "@media (min-width:960px)": {
              paddingLeft: "40px",
            },
          },
          "&:last-child": {
            "@media (min-width:960px)": {
              paddingRight: "40px",
            },
          },
        },
        head: {
          fontFamily: "DM Sans",
          fontSize: 14,
          fontWeight: 700,
        },
        body: {
          fontFamily: "Open Sans",
          fontWeight: "normal",
          fontSize: "14px",
        },
        paddingCheckbox: {
          paddingLeft: "20px",
        },
      },
      MuiCheckbox: {
        root: {
          color: "#cccccc",
          paddingRight: "5px",
          "@media (min-width:960px)": {
            padding: "5px",
          },
          "&:hover": {
            backgroundColor: "#F2F2F2",
          },
          "& .MuiSvgIcon-root": {
            color: "#CCCCCC",
          },
          "&$checked": {
            "& .MuiSvgIcon-root": {
              color: "#4646B4",
            },
          },
          "&$disabled": {
            "& .MuiSvgIcon-root": {
              color: "#CCCCCC !important",
            },
          },
        },
        indeterminate: {
          color: "#4646B4",
          "& .MuiSvgIcon-root": {
            color: "#4646B4",
          },
        },
      },
      MuiButton: {
        root: {
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
        label: {
          fontFamily: "DM Sans",
          fontSize: "16px",
          fontWeight: 500,
          lineHeight: "21px",
        },
        contained: {
          minHeight: "50px",
          borderRadius: "2px",
          padding: "6px 25px",
        },
        outlined: {
          minHeight: "50px",
          borderRadius: "2px",
          padding: "6px 25px",
          color: "#333333",
          background: "#F2F4F9",
          border: "none",
          "&:hover": {
            background: "#DADCE1",
          },
        },
        text: {
          minHeight: "50px",
          "&:hover": {
            color: "#4646B4",
            backgroundColor: "transparent",
          },
        },
        iconSizeMedium: {
          fontSize: "25px",
        },
      },
      MuiIconButton: {
        root: {
          padding: "16px",
          "&:hover": {
            backgroundColor: "#F2F2F2",
          },
        },
      },
      MuiInputBase: {
        root: {
          overflow: "hidden",
          background: "#F2F4F9",
          height: "50px",
          maxWidth: "500px",
          border: "1.5px solid transparent",
          borderRadius: "2px",
          transition: "0.1s all ease-out",
          marginTop: "4px",
          "&.Mui-error": {
            borderColor: "#FF003C",
          },
          "&:hover": {
            border: "1.5px solid #4646B4",
          },
          "&input:active": {
            border: "1.5px solid #4646B4",
            outline: "none",
          },
          "&:focus": {
            border: "1.5px solid #4646B4",
            outline: "none",
          },
          "&$focused": {
            border: "1.5px solid #4646B4",
          },
          "& input, select": {
            padding: "15px 16px 15px 16px",
          },
          "&.Mui-disabled": {
            "&:hover": {
              border: "1.5px solid transparent",
            },
            "&input:active": {
              border: "1.5px solid transparent",
              outline: "none",
            },
            "&:focus": {
              border: "1.5px solid transparent",
              outline: "none",
            },
            "&$focused": {
              border: "1.5px solid transparent",
            },
          },
        },
        multiline: {
          height: "auto",
          padding: "15px",
          overflowY: "auto",
        },
        input: {
          fontFamily: "Open Sans",
          color: "#333333",
          fontWeight: 400,
          fontSize: "16px",
          "&::placeholder": {
            textOverflow: "ellipsis !important",
            color: "#808080",
            opacity: 1,
            fontWeight: 400,
            fontSize: "16px",
          },
        },
        fullWidth: {
          maxWidth: "none",
        },
      },
      MuiSelect: {
        root: {
          padding: "15px 16px 15px 16px",
        },
        icon: {
          top: "50%",
          right: "16px",
          transform: "translateY(-50%)",
        },
        select: {
          "&:focus": {
            backgroundColor: "transparent",
          },
        },
      },
      MuiInput: {
        underline: {
          "&:before": {
            content: 0,
          },
          "&:after": {
            content: 0,
          },
        },
      },
      MuiNativeSelect: {
        icon: {
          top: "50%",
          right: "16px",
          transform: "translateY(-50%)",
        },
        select: {
          "&:focus": {
            backgroundColor: "transparent",
          },
        },
      },
      MuiSvgIcon: {
        root: {
          color: "#333333",
        },
      },
      MuiFormLabel: {
        root: {
          color: "#333333",
          fontWeight: 700,
          fontFamily: "DM Sans",
          fontSize: "14px",
          lineHeight: "18.23px",
          marginTop: "-6px",
        },
      },
      MuiButtonBase: {
        root: {
          "&$disabled .MuiSvgIcon-root": {
            color: "#B0AFB5",
          },
        },
      },
      MuiTableRow: {
        root: {
          height: "60px",
          "& .MuiButton-text": {
            minHeight: "auto",
          },
        },
        hover: {
          transition: "ease-out all 0.12s",
          "&:hover": {
            backgroundColor: "#FBFBFB !important",
          },
        },
      },
      MuiFormControlLabel: {
        root: {
          "&#is-important .MuiFormControlLabel-label": {
            fontSize: "14px",
          },
        },
      },
    },
  })
);

declare module "@material-ui/core/styles/createPalette" {
  //eslint-disable-next-line
  interface Palette {
    grayScale: Palette["primary"];
    additional: {
      lightBlueDarker: string;
      disabled: string;
      errorText: string;
      errorBg: string;
    };
  }
  //eslint-disable-next-line
  interface PaletteOptions {
    grayScale: PaletteOptions["primary"];
    additional: {
      lightBlueDarker: string;
      disabled: string;
      errorText: string;
      errorBg: string;
    };
  }
}
