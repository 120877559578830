import React, { useEffect, useState } from "react";
import PersonalDetailsForm from "../../components/PersonalDetails";
import { Box, Button, Dialog, Typography } from "@material-ui/core";
import useMe from "../../utils/use-me";
import { useAuth } from "../../utils/use-auth";
import { IPersonalDetailsFields } from "../../types/accountTypes";
import { useTranslation } from "react-i18next";
import { validateAccountFields } from "../../utils/fieldsValidation";

const ConfirmProfile = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const {
    me,
    isLoading: loadingMe,
    updateMyAccount,
    selectedCompany,
  } = useMe();
  const { impersonationDone } = useAuth();
  const [isOpen, setIsOpen] = useState<boolean>(!impersonationDone);
  const [errorField, setErrorField] = useState<string>("");
  const [fields, setFields] = useState<IPersonalDetailsFields>({
    name: "",
    email: "",
    phone: "",
    position: "",
    allowMarketingCommunications: false,
  });

  useEffect(() => {
    if (
      !loadingMe &&
      me &&
      !impersonationDone &&
      ((selectedCompany && !selectedCompany.position) ||
        me.confirmationRequired)
    ) {
      setIsOpen(true);
    } else setIsOpen(false);
    //eslint-disable-next-line
  }, [loadingMe, me, selectedCompany]);

  useEffect(() => {
    if (me !== undefined) {
      setFields({
        name: me.name,
        email: me.email,
        phone: me.phone ? me.phone : "",
        position: "",
        allowMarketingCommunications: me.allowMarketingCommunications,
      });
    }
  }, [me]);

  const onCountryCodeChange = (value: string) => {
    setFields({
      ...fields,
      phone: value,
    });
  };

  const updateFields = e => {
    const { name, value, checked } = e.target;
    setErrorField("");
    setFields({
      ...fields,
      [name]: name === "allowMarketingCommunications" ? checked : value,
    });
  };

  const submitForm = e => {
    e.preventDefault();
    const validation = validateAccountFields(fields);
    if (validation === "") updateMyAccount(fields);
    else setErrorField(validation);
  };

  return (
    <Dialog
      open={isOpen}
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="sm"
      fullWidth={true}
      PaperProps={{ square: true }}
    >
      <Box padding="33px 40px 40px">
        <Box>
          <Typography variant="h3">
            {t("dialog.confirm_your_profile")}
          </Typography>
        </Box>
        <Box marginTop="29px">
          <form onChange={updateFields} onSubmit={submitForm}>
            <PersonalDetailsForm
              fields={fields}
              onCountryCodeChange={onCountryCodeChange}
              errorField={errorField}
            />
            <Box fontSize={12} marginTop="34px" marginBottom="11px">
              <span>
                {t("caption.by_confirm_accept")}{" "}
                <a
                  href={`${process.env.REACT_APP_BALTHAUS_URL}/${language}/apsauga/`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Typography
                    color="textPrimary"
                    component="span"
                    style={{ fontSize: 12, fontWeight: 600 }}
                  >
                    {t("caption.privacy_policy")}.
                  </Typography>
                </a>
              </span>
            </Box>
            <Box width="240px">
              <Button
                variant="contained"
                fullWidth
                type="submit"
                color="primary"
              >
                {t("buttons.confirm")}
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ConfirmProfile;
