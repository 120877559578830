import React from "react";
import { useTranslation } from "react-i18next";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../../utils/use-auth";
import useMe from "../../utils/use-me";

const PrivateRoute = ({
  component: Component,
  routePermission,
  requiredRoles,
  ...rest
}) => {
  const { isLoggedIn } = useAuth();
  const { me, role } = useMe();
  const {
    i18n: { language },
  } = useTranslation();
  const hasCorrectRole = role ? requiredRoles.includes(role) : true;
  const hasCorrectPermissions =
    routePermission !== null && me
      ? me.permissions.includes(routePermission)
      : true;
  const hasAccess = hasCorrectRole && hasCorrectPermissions;

  if (!isLoggedIn) {
    localStorage.removeItem("tokens");
    window.location.replace(
      `${process.env.REACT_APP_BALTHAUS_URL}${
        language === "en" ? "/en/login" : "/prisijunkite"
      }`
    );
  }

  return (
    <Route
      {...rest}
      render={props =>
        hasAccess ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

export default PrivateRoute;
