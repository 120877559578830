import { IMenuItem } from "../types/menuTypes";

export const adminItems: IMenuItem[] = [
  {
    name: "clients",
    path: "/clients",
    children: null,
    permission: null,
  },
  {
    name: "requests",
    path: "/requests",
    children: null,
    permission: null,
  },
  {
    name: "announcements",
    path: "/announcements",
    children: null,
    permission: "viewAnnouncements",
  },
  {
    name: "rules",
    path: "/rules",
    children: null,
    permission: "viewRules",
  },
  {
    name: "administrators",
    path: "/administrators",
    children: null,
    permission: "viewAdmins",
  },
  {
    name: "content",
    path: "/content",
    children: null,
    permission: "viewContent",
  },
];

export const clientItems: IMenuItem[] = [
  {
    name: "home",
    path: "/dashboard",
    children: null,
    permission: null,
  },
  {
    name: "invoices",
    path: "/invoices",
    children: [
      {
        name: "all_invoices",
        path: "/invoices",
      },
      {
        name: "debt_act",
        path: "/invoices/debt-act",
      },
    ],
    permission: null,
  },
  {
    name: "requests",
    path: "/requests",
    children: null,
    permission: null,
  },
  {
    name: "announcements",
    path: "/announcements",
    children: null,
    permission: null,
  },
  {
    name: "my_lease",
    path: "/contracts",
    children: [
      {
        name: "contracts",
        path: "/contracts",
      },
      {
        name: "rules",
        path: "/contracts/rules",
      },
      {
        name: "cpi",
        path: "/contracts/cpi",
      },
    ],
    permission: null,
  },
  {
    name: "reports",
    path: "/reports",
    children: null,
    permission: null,
  },
];
