import React from "react";
import {
  Box,
  TextField,
  Typography,
  Checkbox,
  InputLabel,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useStyles } from "./PersonalDetails.styles";
import MuiPhoneNumber from "material-ui-phone-number";
import { IPersonalDetailsFields } from "../../types/accountTypes";
import Validation from "./children/Validation";

interface IProps {
  fields: IPersonalDetailsFields;
  onCountryCodeChange: (value: string) => void;
  disableEmail?: boolean;
  errorField?: string;
}

const PersonalDetailsForm = (props: IProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { fields, onCountryCodeChange, disableEmail, errorField } = props;
  const classes = useStyles();

  return (
    <Box width="100%" maxWidth="500px">
      <Box marginBottom="16px">
        <InputLabel>{t("fields.name")}*</InputLabel>
        <Box position="relative">
          <TextField fullWidth name="name" value={fields.name} />
          {errorField === "name" && <Validation />}
        </Box>
      </Box>
      <Box marginBottom="16px">
        <InputLabel>{t("fields.position")}*</InputLabel>
        <Box position="relative">
          <TextField fullWidth name="position" value={fields.position} />
          {errorField === "position" && <Validation />}
        </Box>
      </Box>
      <Box marginBottom="16px">
        <InputLabel>{t("fields.phone_number")}*</InputLabel>
        <Box position="relative">
          <MuiPhoneNumber
            defaultCountry="lt"
            disableAreaCodes
            fullWidth
            regions="europe"
            name="phone"
            value={fields.phone}
            onChange={onCountryCodeChange}
          />
          {errorField === "phone" && <Validation />}
        </Box>
      </Box>
      {disableEmail ? (
        <Box marginBottom="20px">
          <InputLabel>{t("fields.email")}</InputLabel>
          <Typography variant="body1" gutterBottom>
            {fields.email}
          </Typography>
        </Box>
      ) : (
        <Box marginBottom="20px">
          <InputLabel>{t("fields.email")}*</InputLabel>
          <Box position="relative">
            <TextField fullWidth name="email" value={fields.email} />
            {errorField === "email" && <Validation />}
          </Box>
        </Box>
      )}
      <Box display="flex" flexDirection="row" alignItems="center">
        <Checkbox
          className={classes.checkbox}
          color="primary"
          name="allowMarketingCommunications"
          checked={fields.allowMarketingCommunications}
        />

        <Typography>
          {t("account.allow_marketing")}{" "}
          <a
            href={`${process.env.REACT_APP_BALTHAUS_URL}/${language}/apsauga/`}
            rel="noopener noreferrer"
            target="_blank"
          >
            <Typography
              color="textPrimary"
              component="span"
              style={{ fontWeight: 600 }}
            >
              {t("buttons.more")}
            </Typography>
          </a>
          .
        </Typography>
      </Box>
    </Box>
  );
};

export default PersonalDetailsForm;
