import React, { useState } from "react";
import useUnreadAnnouncements from "../../utils/use-unread-announcements";
import { useHistory } from "react-router-dom";
import AnnouncementDialog from "../../components/AnnouncementDialog/AnnouncementDialog";
import useMe from "../../utils/use-me";

const UnreadAnnouncements = () => {
  const history = useHistory();
  const {
    importantAnnouncements,
    isLoading,
    isError,
  } = useUnreadAnnouncements();
  const { me, isLoading: loadingMe, selectedCompany } = useMe();
  const confirmationRequired =
    !loadingMe &&
    ((me && me.confirmationRequired) ||
      (selectedCompany && !selectedCompany.position));
  const [isOpen, setIsOpen] = useState<boolean>(true);

  if (
    isLoading ||
    isError ||
    importantAnnouncements.length < 1 ||
    confirmationRequired
  )
    return null;

  const readMoreHandler = (id: string) => {
    setIsOpen(false);
    history.push(`/announcements/${id}`, id);
  };

  const closeDialog = (id: string) => {
    setIsOpen(false);
  };

  return (
    <AnnouncementDialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      announcement={importantAnnouncements[0]}
      submitHandler={readMoreHandler}
      closeHandler={closeDialog}
    />
  );
};

export default UnreadAnnouncements;
