import {
  Box,
  Container,
  IconButton,
  Slide,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../Button";
import { useStyles } from "./CookiesPopup.styles";
import ReactGA from "react-ga";
import { useHistory } from "react-router-dom";

const CookiesPopup = () => {
  const classes = useStyles();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    const hasCookies = localStorage.getItem("bhss-open-cookies");
    if (hasCookies !== "true") {
      setIsOpen(true);
    } else {
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_UID);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, []);

  const acceptCookies = useCallback(() => {
    localStorage.setItem("bhss-cookies", "true");
    localStorage.setItem("bhss-open-cookies", "true");
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_UID);
    ReactGA.pageview(window.location.pathname + window.location.search);
    setIsOpen(false);
  }, []);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [history]);

  const closeCookies = useCallback(() => {
    localStorage.setItem("bhss-open-cookies", "true");
    setIsOpen(false);
  }, []);

  return (
    <Slide in={isOpen} direction="up">
      <Box className={classes.wrapper}>
        <Container>
          <Box className={classes.innerWrapper}>
            <Box paddingRight="20px">
              <Typography variant="body1">
                {t("dialog.cookies_1")}{" "}
                <a
                  href={`${process.env.REACT_APP_BALTHAUS_URL}/${language}/apsauga/`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Typography
                    color="textPrimary"
                    component="span"
                    style={{ fontWeight: 600 }}
                  >
                    {t("caption.privacy_policy").toLowerCase()}.
                  </Typography>
                </a>{" "}
                {t("dialog.cookies_2")}
              </Typography>
            </Box>

            <Box className={classes.buttonWrapper}>
              <Button
                className={classes.button}
                variant="contained"
                label={t("buttons.accept")}
                color="primary"
                minWidth="176px"
                onClick={acceptCookies}
              />
              <IconButton
                onClick={closeCookies}
                size="small"
                classes={{ root: classes.close }}
              >
                <Close />
              </IconButton>
            </Box>
          </Box>
        </Container>
      </Box>
    </Slide>
  );
};

export default CookiesPopup;
