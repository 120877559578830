import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Box,
  ListItem,
  Container,
  Grid,
  Hidden,
  Drawer,
  IconButton,
  useTheme,
  useMediaQuery,
  Divider,
} from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";
import LanguageSwitcher from "./children/LanguageSwitcher";
import { useStyles } from "./Header.styles";
import Menu from "./children/Menu";
import { useAuth } from "../../utils/use-auth";
import { clientItems, adminItems } from "../../utils/menuItems";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useMe from "../../utils/use-me";
import Typography from "../../components/Typography";
import Logo from "../../assets/logo.svg";
import useUnreadAnnouncements from "../../utils/use-unread-announcements";
import Badge from "../../components/Badge";
import CloseIcon from "@material-ui/icons/Close";
import TopHeader from "./children/TopHeader";

const Header = () => {
  const classes = useStyles();
  const location = useLocation();
  const { isAdmin, role, permissions } = useMe();
  const { isLoggedIn } = useAuth();
  const {
    unreadAnnouncements,
    isLoading: loadingMessages,
  } = useUnreadAnnouncements();
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const theme = useTheme();
  const padding = useMediaQuery(theme.breakpoints.up("md")) ? "0" : "8px";

  useEffect(() => {
    setOpen(false);
    document.body.style.overflow = "";
  }, [location.pathname]);

  const toggleDrawer = () => {
    if (open) document.body.style.overflow = "";
    else document.body.style.overflow = "hidden";
    setOpen(!open);
  };

  const menuItems =
    isLoggedIn && role !== null
      ? (!isAdmin ? clientItems : adminItems).map((menuItem, index) => {
          if (
            isAdmin &&
            menuItem.permission !== null &&
            !permissions.includes(menuItem.permission)
          )
            return null;

          return (
            <Box key={index}>
              <NavLink
                exact={menuItem.path === "/"}
                to={menuItem.path}
                key={index}
                activeClassName={classes.navActive}
              >
                <ListItem button disableRipple>
                  <Box paddingY={padding}>
                    <Badge
                      invisible={
                        loadingMessages ||
                        menuItem.name !== "announcements" ||
                        isAdmin ||
                        (unreadAnnouncements !== null &&
                          unreadAnnouncements.unreadCount < 1)
                      }
                      variant="dot"
                      color="error"
                    >
                      <Typography
                        variant="subtitle1"
                        color="textPrimary"
                        classes={{ root: classes.textActive }}
                      >
                        {t(`pages.${menuItem.name}`)}
                      </Typography>
                    </Badge>
                  </Box>
                </ListItem>
              </NavLink>
            </Box>
          );
        })
      : null;

  return (
    <AppBar
      position="relative"
      color="transparent"
      className={classes.appBar}
      elevation={0}
    >
      <Hidden smDown>
        <TopHeader />
      </Hidden>
      <Toolbar className={classes.toolbar}>
        <Box bgcolor="white" width="100%" paddingY="10px">
          <Container>
            <Grid
              container
              spacing={2}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item md={2} xs={6}>
                <NavLink to={isAdmin ? "/clients" : "/dashboard"}>
                  <img
                    src={Logo}
                    alt="Logo"
                    style={{ width: "100%", maxWidth: "120px" }}
                  />
                </NavLink>
              </Grid>
              <Hidden smDown>
                <Grid item md={8}>
                  <Box
                    width="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    minHeight="110px"
                    flexWrap="wrap"
                  >
                    {menuItems}
                  </Box>
                </Grid>
              </Hidden>
              <Grid item md={2} xs={6}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  width="100%"
                >
                  <Hidden mdUp>
                    <IconButton
                      classes={{ root: classes.menuButtonRoot }}
                      onClick={toggleDrawer}
                    >
                      <MenuIcon />
                    </IconButton>
                  </Hidden>
                  {isLoggedIn && <Menu />}
                </Box>
              </Grid>
            </Grid>
            <Hidden mdUp>
              <Drawer
                anchor="right"
                open={open}
                onClose={toggleDrawer}
                classes={{ paper: classes.drawerRoot }}
              >
                <Box paddingLeft="24px" paddingRight="14px" marginTop="8vh">
                  <Box display="flex" justifyContent="flex-end">
                    <IconButton
                      classes={{ root: classes.menuButtonRoot }}
                      onClick={toggleDrawer}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  {menuItems}
                </Box>
                <Divider />
                <Box paddingX="40px" marginTop="33px">
                  <Box marginBottom="12px">
                    <a href="mailto:pagalba@balthaus.eu">
                      <Typography
                        customVariant="subtitle3"
                        color="textSecondary"
                      >
                        pagalba@balthaus.eu
                      </Typography>
                    </a>
                  </Box>
                  <Box>
                    <a href="tel:870040020">
                      <Typography
                        customVariant="subtitle3"
                        color="textSecondary"
                      >
                        8 700 400 20
                      </Typography>
                    </a>
                  </Box>
                  <Box marginLeft="-12px">
                    <LanguageSwitcher />
                  </Box>
                </Box>
              </Drawer>
            </Hidden>
          </Container>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
export default Header;
