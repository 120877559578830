import useSWR from "swr";
import { mutateData } from "./api";
import { useAuth } from "./use-auth";
import useMe from "./use-me";
import useNotifications from "./use-notifications";
import { useTranslation } from "react-i18next";

const useUnreadAnnouncements = () => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const { showError } = useNotifications();
  const { isLoggedIn, isImpersonating, impersonationDone } = useAuth();
  const { isAdmin, isLoading: loadingMe, role } = useMe();
  const isNotAdmin = !loadingMe && role !== null && !isAdmin;
  const url =
    isLoggedIn && !isImpersonating && !impersonationDone && isNotAdmin
      ? `/announcements/unread/${lang.toUpperCase()}`
      : null;
  const { data, error, mutate } = useSWR(url);

  const markAsRead = (ids: string[]) => {
    mutateData("patch", "/announcements/markAsRead", { announcementsIds: ids })
      .then(() => {
        mutate();
      })
      .catch(error => {
        if (error.response && error.response.data) {
          const errorData = error.response.data;
          const message = errorData.subCode
            ? t(`error.${errorData.subCode}`)
            : errorData.message;
          showError(t("error.something_went_wrong"), message);
        } else {
          showError(t("error.something_went_wrong"), "");
        }
      });
  };

  return {
    unreadAnnouncements: data ? data : null,
    importantAnnouncements: data ? data.importantAnnouncements : null,
    isLoading: !error && !data,
    isError: error,
    markAsRead,
  };
};

export default useUnreadAnnouncements;
