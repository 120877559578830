import React, { useState, useMemo, useCallback, useEffect } from "react";
import { createEditor, Node } from "slate";
import { Slate, Editable, withReact } from "slate-react";
import useSlateMethods from "../../utils/use-slate-methods";
import Element from "../SlateEditor/components/Element";
import Leaf from "../SlateEditor/components/Leaf";

interface IProps {
  message: string;
}

const ReadOnlyEditor = (props: IProps) => {
  const { message } = props;
  const editor = useMemo(() => withReact(createEditor()), []);
  const renderElement = useCallback(props => <Element {...props} />, []);
  const renderLeaf = useCallback(props => <Leaf {...props} />, []);
  const { deserialize, parseHtmlToDoc } = useSlateMethods();
  const [value, setValue] = useState<Node[]>(
    deserialize(parseHtmlToDoc(message))
  );

  useEffect(() => {
    setValue(deserialize(parseHtmlToDoc(message)));
    //eslint-disable-next-line
  }, [message]);

  return (
    <Slate editor={editor} value={value} onChange={value => setValue(value)}>
      <Editable
        readOnly
        renderElement={renderElement}
        renderLeaf={renderLeaf}
      />
    </Slate>
  );
};

export default ReadOnlyEditor;
