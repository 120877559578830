import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  menuList: {
    paddingTop: "1px",
    minWidth: "240px",
    marginTop: "20px",
    "&:focus": {
      outline: "none",
    },
  },
  menuItem: {
    padding: "5px 22px",
    margin: "15px 0",
    "& $avatar": {
      color: theme.palette.text.primary,
    },
    "&:hover": {
      backgroundColor: "#FBFBFB",
      "& $avatar": {
        color: theme.palette.primary.main,
      },
      "& $companyName": {
        color: theme.palette.primary.main,
      },
    },
  },
  menuItemSmall: {
    padding: "10px 20px",
    minHeight: "unset",
  },
  language: {
    fontWeight: 600,
  },
  languageActive: {
    color: theme.palette.primary.main,
  },
  langItem: {
    paddingLeft: "20px",
    paddingRight: "25px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  chevron: {
    transform: "rotateZ(-90deg)",
    transition: "0.2s ease-out",
    color: theme.palette.text.secondary,
  },
  chevronPrimary: {
    transform: "rotateZ(-90deg)",
    transition: "0.2s ease-out",
    color: theme.palette.primary.main,
  },
  chevronActive: {
    transform: "rotateZ(90deg)",
  },
  avatar: {
    fontSize: "20px !important",
    fontWeight: 700,
    fontFamily: "DM Sans !important",
    marginRight: "12.5px",
    [theme.breakpoints.down("sm")]: {
      marginRight: "5px",
    },
  },
  textPrimary: {
    backgroundColor: `${theme.palette.background.default} !important`,
    color: `${theme.palette.text.primary}`,
  },
  colorPrimary: {
    backgroundColor: `${theme.palette.background.default} !important`,
    color: `${theme.palette.primary.main} !important`,
  },
  avatarButton: {
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      marginLeft: "14px",
      width: "40px",
      minWidth: 0,
      minHeight: "50px",
    },
  },
  companyName: {},
}));
