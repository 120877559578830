import React from "react";
import MuiBadge, { BadgeProps } from "@material-ui/core/Badge";
import { useStyles } from "./Badge.styles";

interface IProps extends BadgeProps {
  children: any;
  invisible: boolean;
}

const Badge = (props: IProps) => {
  const { children, invisible, ...rest } = props;
  const classes = useStyles();

  return (
    <MuiBadge
      color="error"
      invisible={invisible}
      classes={{ dot: classes.align }}
      {...rest}
    >
      {children}
    </MuiBadge>
  );
};

export default Badge;
