import React, { useState, useEffect, createContext, useContext } from "react";
import useSWR, { mutate } from "swr";
import { useHistory, useLocation } from "react-router-dom";
import { useAuth } from "./use-auth";
import { mutateData } from "./api";
import { IUserBody } from "../types/accountTypes";
import { setCompanyIdHeader } from "./api";
import { parse } from "query-string";
import useNotifications from "./use-notifications";
import { useTranslation } from "react-i18next";
import { IUseMeContext, TAdminPermission } from "../types/userTypes";

const meContext = createContext<IUseMeContext>({
  me: null,
  role: null,
  switchUser: () => {},
  isLoading: false,
  isError: false,
  isAdmin: false,
  selectedCompany: null,
  isCompanyRoot: false,
  permissions: [],
  isCompanyAdmin: false,
  isRoot: false,
  updateMyAccount: (
    userDetails: IUserBody,
    callback?: (res: any) => void
  ) => {},
});

export const ProvideMe = ({ children }) => {
  const me = useProvideMe();
  return <meContext.Provider value={me}>{children}</meContext.Provider>;
};

export const useMe = () => {
  return useContext(meContext);
};

const useProvideMe = () => {
  const { isLoggedIn, isImpersonating, impersonationDone } = useAuth();
  const { showSuccess, showError } = useNotifications();
  const { t } = useTranslation();
  const location = useLocation();
  const params = parse(location.search);
  const { data, error } = useSWR(isLoggedIn ? "/users/me" : null);
  const [role, setRole] = useState<string | null>(null);
  const history = useHistory();
  const [selectedCompany, setSelectedCompany] = useState<any>(null);
  const isAdmin = role === "root" || role === "bhAdmin";
  const isCompanyRoot = role === "companyRootAdmin";
  const isCompanyAdmin = role === "companyAdmin";
  const isRoot = role === "root";
  const permissions: TAdminPermission[] = data ? data.permissions : [];

  useEffect(() => {
    if (data && !error) {
      const { companies, role } = data;
      setRole(role);
      if (isCompanyRoot || isCompanyAdmin) {
        const selectedId = findSelectedCompanyId();
        const filteredCompany =
          companies.length > 0
            ? companies.filter(company => company.id === selectedId)[0]
            : null;
        if (filteredCompany !== selectedCompany) {
          setCompanyIdHeader(selectedId);
          setSelectedCompany(filteredCompany);
        }

        if (!("company" in params) && companies.length > 0) {
          history.push({
            pathname: history.location.pathname,
            search: `?company=${selectedId}`,
          });
        }
      }
    }
    //eslint-disable-next-line
  }, [data, error, params]);

  useEffect(() => {
    if (!isImpersonating && isAdmin && impersonationDone) {
      history.push({
        pathname: "/clients",
        search: "",
      });
    }
    //eslint-disable-next-line
  }, [isImpersonating, isAdmin]);

  useEffect(() => {
    if ((isCompanyRoot || isCompanyAdmin) && location.pathname === "/") {
      history.push({ pathname: "/dashboard" });
    }
    //eslint-disable-next-line
  }, [isCompanyRoot, isCompanyAdmin]);

  useEffect(() => {
    if (isAdmin && history.location.pathname === "/") {
      history.push("/clients");
    }
    //eslint-disable-next-line
  }, [isAdmin]);

  const findSelectedCompanyId = () => {
    const { selectedCompanyId } = data;
    if ("company" in params) return params.company;
    if (selectedCompany) return selectedCompany.id;
    return selectedCompanyId;
  };

  const switchUser = (id: string) => {
    const { companies } = data;
    const filteredCompany =
      data.companies.length > 0
        ? companies.filter(company => company.id === id)[0]
        : null;
    setSelectedCompany(filteredCompany);
    setCompanyIdHeader(id);
    history.push({
      pathname: "/dashboard",
      search: `?company=${id}`,
    });
  };

  const updateMyAccount = (
    userDetails: IUserBody,
    callback?: (res: any) => void
  ) => {
    mutateData("patch", "/users/me", userDetails)
      .then(res => {
        if (callback) {
          callback(res);
        } else {
          mutate(`/users/me`);
          showSuccess(
            t("success.successfully_changed"),
            t("success.your_profile_was_successfully_changed")
          );
        }
      })
      .catch(error => {
        if (error.response && error.response.data) {
          const errorData = error.response.data;
          const message = errorData.subCode
            ? t(`error.${errorData.subCode}`)
            : errorData.message;
          showError(t("error.something_went_wrong"), message);
        } else {
          showError(t("error.something_went_wrong"), "");
        }
      });
  };

  return {
    me: data,
    role,
    switchUser,
    isLoading: !error && !data,
    isError: error,
    updateMyAccount,
    selectedCompany,
    isAdmin,
    isCompanyRoot,
    permissions,
    isCompanyAdmin,
    isRoot,
  };
};

export default useMe;
