import {
  Collapse,
  Button,
  Popper,
  ClickAwayListener,
  MenuList,
  Paper,
  MenuItem,
  Box,
  Avatar,
  Divider,
  Hidden,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useRef, useState, useEffect } from "react";
import { useStyles } from "./children.styles";
import { useAuth } from "../../../utils/use-auth";
import useMe from "../../../utils/use-me";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ChevronLeft } from "@material-ui/icons";
import Typography from "../../../components/Typography";
import CompanyMenuItem from "./CompanyMenuItem";

const Menu = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { logout } = useAuth();
  const {
    me,
    isLoading,
    isError,
    switchUser,
    selectedCompany,
    isCompanyRoot,
    isRoot,
  } = useMe();
  const anchorRef = useRef<HTMLButtonElement>(null);
  const prevOpen = useRef(open);
  const theme = useTheme();
  const fullWidth = useMediaQuery(theme.breakpoints.up("md")) ? "100%" : "auto";

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  if (isError || isLoading) return null;

  return (
    <Box display="flex" justifyContent="flex-end" width={fullWidth}>
      <Button
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        classes={{ text: classes.avatarButton }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          <Avatar
            classes={{
              root: classes.avatar,
              colorDefault: classes.colorPrimary,
            }}
          >
            {(selectedCompany ? selectedCompany : me).name.charAt(0)}
          </Avatar>
          <Hidden smDown>
            <Typography variant="subtitle1" color="primary" noWrap>
              {(selectedCompany ? selectedCompany : me).name}
            </Typography>
            <ChevronLeft
              className={`${classes.chevronPrimary} ${
                open && classes.chevronActive
              }`}
            />
          </Hidden>
        </Box>
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Collapse
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper square elevation={2}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                  classes={{ root: classes.menuList }}
                >
                  {me.companies.length > 0 &&
                    me.companies.map(company => {
                      if (company.id !== selectedCompany.id) return null;
                      return (
                        <CompanyMenuItem
                          key={company.id}
                          company={company}
                          switchUser={switchUser}
                          handleClose={handleClose}
                          disabled={true}
                        />
                      );
                    })}
                  {me.companies.length > 1 &&
                    me.companies.map(company => {
                      if (company.id === selectedCompany.id) return null;
                      return (
                        <CompanyMenuItem
                          key={company.id}
                          company={company}
                          switchUser={switchUser}
                          handleClose={handleClose}
                          disabled={false}
                        />
                      );
                    })}
                  {me.companies.length > 1 && <Divider />}
                  <MenuItem
                    onClick={e => {
                      history.push("/account");
                      handleClose(e);
                    }}
                    classes={{ root: `${classes.menuItemSmall}` }}
                  >
                    <Typography customVariant="subtitle3">
                      {t("pages.account")}
                    </Typography>
                  </MenuItem>
                  {isCompanyRoot && (
                    <MenuItem
                      onClick={e => {
                        history.push("/users");
                        handleClose(e);
                      }}
                      classes={{ root: `${classes.menuItemSmall}` }}
                    >
                      <Typography customVariant="subtitle3">
                        {t("pages.users")}
                      </Typography>
                    </MenuItem>
                  )}
                  {isRoot && (
                    <MenuItem
                      onClick={e => {
                        history.push("/logs");
                        handleClose(e);
                      }}
                      classes={{ root: `${classes.menuItemSmall}` }}
                    >
                      <Typography customVariant="subtitle3">
                        {t("pages.logs")}
                      </Typography>
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={logout}
                    classes={{ root: `${classes.menuItemSmall}` }}
                  >
                    <Typography customVariant="subtitle3">
                      {t("buttons.logout")}
                    </Typography>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Collapse>
        )}
      </Popper>
    </Box>
  );
};

export default Menu;
