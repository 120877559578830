import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  closeIcon: {
    cursor: "pointer",
  },
  dialogTitle: {
    padding: 0,
    maxWidth: "100%",
  },
  dialogContent: {
    padding: "0 40px",
  },
  actionButtons: {
    justifyContent: "start",
    padding: "25px 40px 40px",
  },
}));
