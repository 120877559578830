import useSWR, { mutate } from "swr";
import { mutateData } from "./api";
import { useAuth } from "./use-auth";
import useMe from "./use-me";
import useNotifications from "./use-notifications";
import { useTranslation } from "react-i18next";

const useCpi = () => {
  const { showError } = useNotifications();
  const { t } = useTranslation();
  const { isLoggedIn } = useAuth();
  const { isAdmin, isLoading: loadingMe, role } = useMe();
  const isNotAdmin = !loadingMe && role !== null && !isAdmin;
  const url = isLoggedIn && isNotAdmin ? "/cpi/currentPercent" : null;
  const { data, error } = useSWR(url);

  const acceptCPIChange = () => {
    mutateData("patch", "/users/acceptCPIChange")
      .then(res => {
        mutate(`/users/me`);
      })
      .catch(error => {
        if (error.response && error.response.data) {
          const errorData = error.response.data;
          const message = errorData.subCode
            ? t(`error.${errorData.subCode}`)
            : errorData.message;
          showError(t("error.something_went_wrong"), message);
        } else {
          showError(t("error.something_went_wrong"), "");
        }
      });
  };

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    acceptCPIChange,
  };
};

export default useCpi;
