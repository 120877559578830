import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  closeIcon: {
    cursor: "pointer",
  },
  dialogTitle: {
    padding: "33px 40px 20px 40px",
    [theme.breakpoints.down("xs")]: {
      padding: "20px",
    },
  },
  dialogContent: {
    padding: "0 40px",
    [theme.breakpoints.down("xs")]: {
      padding: "0 20px",
    },
  },
  actionButtons: {
    justifyContent: "start",
    padding: "40px",
    [theme.breakpoints.down("xs")]: {
      padding: "20px",
      margin: "auto",
    },
  },
  submitBtn: {
    width: 240,
    textTransform: "uppercase",
    [theme.breakpoints.down("xs")]: {
      width: 120,
    },
  },
}));
