import React from "react";
import { useSelected, useFocused } from "slate-react";

const ImageElement = ({ attributes, children, element }) => {
  const selected = useSelected();
  const focused = useFocused();
  return (
    <div {...attributes}>
      <div contentEditable={false}>
        <img
          alt="Uploaded"
          src={element.url}
          style={{
            boxShadow: selected && focused ? "0 0 0 3px #B4D5FF" : "none",
            maxHeight: "20em",
            maxWidth: "100%",
            display: "block",
          }}
        />
      </div>
      {children}
    </div>
  );
};

export default ImageElement;
