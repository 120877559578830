import {
  Box,
  Button,
  Slide,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { DesktopAccessDisabled } from "@material-ui/icons";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../utils/use-auth";
import { useStyles } from "./StopImpersonatingButton.styles";

const StopImpersonatingButton = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const bottomMargin = useMediaQuery(theme.breakpoints.down("xs"))
    ? "0"
    : "60px";
  const maxWidth = useMediaQuery(theme.breakpoints.down("xs"))
    ? "initial"
    : "440px";
  const { isImpersonating, stopImpersonating } = useAuth();

  return (
    <Slide direction="left" in={isImpersonating}>
      <Box
        position="fixed"
        width="100vw"
        maxWidth={maxWidth}
        height="60px"
        bottom={bottomMargin}
        left="0"
        right="0"
        margin="auto"
        bgcolor="#059C6C"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Button
          onClick={stopImpersonating}
          classes={{ root: classes.fullSize }}
        >
          <Box
            display="flex"
            alignItems="center"
            width="100%"
            justifyContent="center"
          >
            <Box marginRight="15px" height="24px">
              <DesktopAccessDisabled color="secondary" />
            </Box>
            <Typography variant="h5" color="secondary">
              {t("buttons.stop_impersonating")}
            </Typography>
          </Box>
        </Button>
      </Box>
    </Slide>
  );
};

export default StopImpersonatingButton;
