import React from "react";
import { Box, Typography } from "@material-ui/core";

interface IProps {
  width?: string;
  height?: string;
  alignment?: "flex-start" | "flex-end" | "center";
  marginTop?: string;
  message?: string;
  title: string;
  bgcolor?: string;
}

const Error = (props: IProps) => {
  const {
    width,
    height,
    alignment,
    message,
    title,
    marginTop,
    bgcolor,
  } = props;
  return (
    <Box
      width={width}
      height={height}
      display="flex"
      alignItems={alignment}
      justifyContent={alignment}
      marginTop={marginTop}
      flexDirection="column"
      bgcolor={bgcolor}
    >
      <Typography variant="h3" color="error" gutterBottom>
        {title}
      </Typography>
      <Typography variant="subtitle1" color="error">
        {message}
      </Typography>
    </Box>
  );
};

export default Error;
