import React from "react";
import useNotifications from "../../utils/use-notifications";
import Notifications from "../../components/Notifications/Notifications";

const NotificationContainer = () => {
  const { list } = useNotifications();
  return <Notifications notificationList={list} />;
};

export default NotificationContainer;
