import React from "react";
import { useStyles } from "./Button.styles";
import { Button as MUIButton, Box, ButtonProps } from "@material-ui/core";

interface IProps extends ButtonProps {
  label: any;
  btnStyle?: "error" | "square";
  minWidth?: string;
  maxWidth?: string;
  paddingRight?: string;
  paddingTop?: string;
  className?: string;
  margin?: string;

  // MUI props go in ...rest
  variant: "text" | "outlined" | "contained";
  type?: "button" | "reset" | "submit";
  color?: "primary" | "secondary" | "inherit";
  onClick?: (any) => void;
  endIcon?: any;
  startIcon?: any;
  name?: string;
}

const Button = (props: IProps) => {
  const {
    label,
    btnStyle,
    minWidth,
    maxWidth,
    paddingRight,
    className,
    margin,
    paddingTop,
    ...rest
  } = props;
  const classes = useStyles();
  return (
    <Box
      minWidth={minWidth}
      paddingRight={paddingRight}
      maxWidth={maxWidth}
      margin={margin}
      paddingTop={paddingTop}
    >
      <MUIButton
        fullWidth
        className={`${
          btnStyle in classes ? classes[btnStyle] : ""
        } ${className}`}
        {...rest}
      >
        {label}
      </MUIButton>
    </Box>
  );
};

export default Button;
