import useSWR from "swr";
import { mutateData } from "./api";
import { useHistory } from "react-router-dom";
import useMe from "./use-me";
import useNotifications from "./use-notifications";
import { useTranslation } from "react-i18next";
import { pagesTypes } from "./contentTypes";

const useContent = (type: string, lang: string) => {
  const isPageType = type && pagesTypes.some(el => el["type"] === type);
  const { showSuccess, showError } = useNotifications();
  const { t } = useTranslation();
  const history = useHistory();
  const { isLoading: loadingMe } = useMe();
  const url = !loadingMe && isPageType ? `/content/${type}/${lang}` : null;
  const { data, error } = useSWR(url);

  const setContent = (content: string) => {
    return mutateData("put", url, { content: content })
      .then(res => {
        history.push("/content");
        showSuccess(
          t("success.successfully_changed"),
          t("success.content_was_successfully_changed")
        );
      })
      .catch(error => {
        if (error.response && error.response.data) {
          const errorData = error.response.data;
          const message = errorData.subCode
            ? t(`error.${errorData.subCode}`)
            : errorData.message;
          showError(t("error.something_went_wrong"), message);
        } else {
          showError(t("error.something_went_wrong"), "");
        }
      });
  };

  return {
    content: data,
    isLoading: type ? !error && !data : false,
    isError: error,
    setContent,
  };
};

export default useContent;
