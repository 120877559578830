import { Box, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./children.styles";
import ErrorIcon from "@material-ui/icons/Error";

const Validation = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box className={classes.container}>
      <Box className={classes.error}>
        <Box className={classes.arrow} />
        <Box display="flex" alignItems="center">
          <Box marginRight="14px" height="20px" fontSize="20px">
            <ErrorIcon color="error" fontSize="inherit" />
          </Box>
          <Typography variant="body1">{t("field.required")}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Validation;
