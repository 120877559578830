import React from "react";
import MuiTypography, { TypographyProps } from "@material-ui/core/Typography";
import { useStyles } from "./Typography.styles";

interface IProps extends TypographyProps {
  customVariant?: "h7" | "subtitle3" | "subtitle4" | "body3" | "body4";
  component?: "span";
}

const Typography = (props: IProps) => {
  const { children, component, customVariant, ...rest } = props;
  const classes = useStyles();
  return (
    <MuiTypography
      component={component}
      className={classes[customVariant]}
      {...rest}
    >
      {children}
    </MuiTypography>
  );
};

export default Typography;
