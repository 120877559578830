import React from "react";
import { Container, useMediaQuery } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { ReactComponent as Floater } from "../../assets/icons/floater.svg";
import theme from "../../styles/theme";

const AppContainer = (props: any) => {
  const { children } = props;
  const location = useLocation();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Container>
      {location.pathname !== "/dashboard" && mdUp && <Floater />}
      {children}
    </Container>
  );
};

export default AppContainer;
