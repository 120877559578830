import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "./locales/en.json";
import translationLT from "./locales/lt.json";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translations: translationEN,
    },
    lt: {
      translations: translationLT,
    },
  },

  compatibilityJSON: "v1",
  fallbackLng: "lt",
  debug: false,
  lng: localStorage.getItem("bhss-lang") || "lt",
  ns: ["translations"],
  defaultNS: "translations",
  react: {
    wait: true,
  },
});

export default i18n;
