import React from "react";
import { Box, Container } from "@material-ui/core";
import Typography from "../../../components/Typography";
import LanguageSwitcher from "./LanguageSwitcher";

const TopHeader = () => {
  return (
    <Container>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        minHeight="40px"
      >
        <Box marginRight="19px">
          <a href="mailto:pagalba@balthaus.eu">
            <Typography customVariant="subtitle3" color="textSecondary">
              pagalba@balthaus.eu
            </Typography>
          </a>
        </Box>
        <Box marginRight="18px">
          <a href="tel:870040020">
            <Typography customVariant="subtitle3" color="textSecondary">
              8 700 400 20
            </Typography>
          </a>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center">
          <LanguageSwitcher />
        </Box>
      </Box>
    </Container>
  );
};

export default TopHeader;
