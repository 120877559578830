import React from "react";
import {
  Box,
  LinearProgress,
  CircularProgress,
  CircularProgressProps,
} from "@material-ui/core";

interface IProps {
  width?: string;
  height?: string;
  alignment?: "flex-start" | "flex-end" | "center";
  isLinear?: boolean;
  color?: CircularProgressProps["color"];
}

const Loader = (props: IProps) => {
  const { width, height, alignment, isLinear, color } = props;
  return (
    <Box
      width={width}
      height={height}
      display="flex"
      alignItems={alignment}
      justifyContent={alignment}
      flexDirection="column"
    >
      {isLinear ? <LinearProgress /> : <CircularProgress color={color} />}
    </Box>
  );
};

export default Loader;
