import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: "fixed",
    top: 50,
    right: 30,
    zIndex: 9999,
    [theme.breakpoints.down("sm")]: {
      right: 8,
      left: 8,
      top: 50,
    },
  },
  notification: {
    margin: "8px 0",
    width: 580,
    position: "relative",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      width: "unset",
    },
  },
  icon: {
    fontSize: 54,
  },
}));
