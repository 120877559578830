import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    margin: "auto",
    zIndex: 10,
    background: theme.palette.common.white,
    minHeight: 80,
    padding: "15px 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.3)",
  },
  button: {
    height: "40px",
    backgroundColor: "#A4AAF5",
    "&:hover": {
      backgroundColor: "#8890F5",
    },
  },
  close: {
    marginRight: 2,
    marginLeft: 39,
  },
  innerWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      alignItems: "flex-start",
    },
  },
  buttonWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-between",
      width: "100%",
      marginBottom: 10,
    },
  },
}));
