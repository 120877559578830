import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    [theme.breakpoints.up("md")]: {
      minHeight: 130,
    },
  },
  toolbar: {
    padding: 0,
  },
  textActive: {
    position: "relative",
    "&::before": {
      content: '" "',
      position: "absolute",
      width: "100%",
      height: "1.5px",
      bottom: 0,
      left: 0,
      background: theme.palette.primary.main,
      visibility: "hidden",
      borderRadius: "5px",
      transform: "scaleX(0)",
      transition: " .2s ease-out",
    },
    "&:hover::before": {
      visibility: "visible",
      transform: "scaleX(1)",
    },
  },
  navActive: {
    "& $textActive": {
      color: `${theme.palette.primary.main} !important`,
      "&::before": {
        visibility: "visible",
        transform: "scaleX(1)",
      },
    },
    "& .MuiTypography-root::after": {
      content: " ",
      width: "100%",
      backgroundColor: theme.palette.primary.main,
    },
  },
  drawerRoot: {
    width: "85%",
  },
  menuButtonRoot: {
    height: "40px",
    width: "40px",
    background: theme.palette.background.default,
  },
}));
