import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: "absolute",
    zIndex: 9,
    bottom: "-100%",
    right: 0,
    backgroundColor: theme.palette.background.paper,
  },
  error: {
    backgroundColor: "rgba(255,0,60,0.1)",
    height: "50px",
    padding: "15px 18px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "2px",
    color: "#FD4671",
    position: "relative",
  },
  arrow: {
    content: "",
    position: "absolute",
    left: "12px",
    top: "-12px",
    zIndex: 1,
    border: "solid 6px transparent",
    borderBottomColor: "rgba(255,0,60,0.1)",
  },
  "@media (max-width: 800px)": {
    arrow: {
      left: 0,
      borderRightColor: "transparent",
      borderBottomColor: "rgba(255,0,60,0.1)",
      right: 0,
      margin: "auto",
      width: "12px",
      top: "-12px",
    },
    error: {
      marginLeft: 0,
      width: "100%",
      maxWidth: "500px",
      justifyContent: "flex-start",
      marginTop: 0,
    },
  },
}));
