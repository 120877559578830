import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  error: {
    color: `${theme.palette.error.main} !important`,
  },
  square: {
    borderRadius: 0,
  },
}));
