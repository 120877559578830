import React from "react";
import { Box, Typography as MuiTypography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Typography from "../Typography";
import { useStyles } from "./CurrentCpi.styles";
import { ICpiInfo } from "../../types/cpiTypes";
import Moment from "react-moment";
import useDateFormat from "../../utils/use-date-format";

interface IProps {
  title: string;
  cpiInfo: ICpiInfo;
  padding: string;
  borderBottom?: boolean;
}

const CurrentCpi = (props: IProps) => {
  const { cpiInfo, padding, title, borderBottom } = props;
  const { t } = useTranslation();
  const { dateFormat } = useDateFormat();
  const classes = useStyles();

  return (
    <Box
      padding={padding}
      bgcolor="#FBFBFB"
      className={borderBottom ? classes.borderBottom : null}
    >
      <MuiTypography variant="h4" gutterBottom>
        {t(title)}:
      </MuiTypography>
      <Box className={classes.infoContainer}>
        <Box display="flex" flexDirection="row" paddingRight={5}>
          <Box paddingRight="4px">
            <Typography customVariant="h7">{t("pages.cpi_type")}:</Typography>
          </Box>
          <Typography customVariant="subtitle3">{cpiInfo.type}</Typography>
        </Box>
        <Box display="flex" flexDirection="row" paddingRight={5}>
          <Box paddingRight="4px">
            <Typography customVariant="h7">{t("pages.percentage")}:</Typography>
          </Box>
          <Typography customVariant="subtitle3">
            {Number(cpiInfo.percent).toFixed(1)}%
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row">
          <Box paddingRight="4px">
            <Typography customVariant="h7">
              {t("pages.date_updated")}:
            </Typography>
          </Box>
          <Typography customVariant="subtitle3">
            <Moment date={cpiInfo.updateDate} format={dateFormat} />
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CurrentCpi;
