import { useTranslation } from "react-i18next";
import { useMemo } from "react";

const useDateFormat = () => {
  const { i18n } = useTranslation();
  const lang = i18n.language;

  const dateFormat = useMemo(
    () => (lang === "en" ? "DD/MM/YYYY" : "YYYY MM DD"),
    [lang]
  );
  const datetimeFormat = useMemo(
    () => (lang === "en" ? "DD/MM/YYYY HH:mm" : "YYYY MM DD HH:mm"),
    [lang]
  );

  return {
    dateFormat,
    datetimeFormat,
  };
};

export default useDateFormat;
