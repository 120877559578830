import React, { useEffect, useState } from "react";
import useMe from "../../utils/use-me";
import CpiChangesDialog from "../../components/CpiChangesDialog";
import useCpi from "../../utils/use-cpi";
import useContent from "../../utils/use-content";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../utils/use-auth";

const CpiChanges = () => {
  const { data: cpi, isLoading: loadingCpi, acceptCPIChange } = useCpi();
  const { impersonationDone } = useAuth();
  const { me, isLoading: loadingMe } = useMe();
  const [isOpen, setIsOpen] = useState<boolean>(!impersonationDone);
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const {
    content: cpiIncreaseNotice,
    isLoading: loadingCpiIncreaseNotice,
  } = useContent("cpiIncreaseNotice", lang.toUpperCase());
  const {
    content: cpiDecreaseNotice,
    isLoading: loadingCpiDecreaseNotice,
  } = useContent("cpiDecreaseNotice", lang.toUpperCase());

  useEffect(() => {
    if (!loadingMe && !loadingCpi) {
      if (
        cpi.currentCPI &&
        cpi.previousCPI &&
        cpi.currentCPI.percent !== cpi.previousCPI.percent &&
        !me.cpiCompliance &&
        !impersonationDone
      )
        setIsOpen(true);
      else setIsOpen(false);
    }
    //eslint-disable-next-line
  }, [loadingMe, me, loadingCpi, cpi]);

  const confirmChangesHandler = () => {
    setIsOpen(false);
    acceptCPIChange();
  };

  if (
    impersonationDone ||
    loadingCpi ||
    loadingMe ||
    loadingCpiIncreaseNotice ||
    loadingCpiDecreaseNotice
  )
    return null;

  return (
    !loadingMe &&
    !loadingCpi && (
      <CpiChangesDialog
        isOpen={isOpen}
        message={
          cpi.currentCPI.percent > cpi.previousCPI.percent
            ? cpiIncreaseNotice.content
            : cpiDecreaseNotice.content
        }
        cpiInfo={cpi}
        confirmHandler={confirmChangesHandler}
      />
    )
  );
};

export default CpiChanges;
