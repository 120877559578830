import React from "react";
import {
  Dialog,
  Box,
  Typography,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import ReadOnlyEditor from "../ReadOnlyEditor/ReadOnlyEditor";
import Button from "../Button";
import { useStyles } from "./AnnouncementDialog.styles";
import { useTranslation } from "react-i18next";

interface IProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  announcement: any;
  submitHandler: (id: string) => void;
  closeHandler: (id: string) => void;
}

const AnnouncementDialog = (props: IProps) => {
  const {
    isOpen,
    setIsOpen,
    closeHandler,
    announcement,
    submitHandler,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog
      onClose={() => setIsOpen(false)}
      open={isOpen}
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="sm"
      fullWidth={true}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        padding="33px 40px 20px 40px"
      >
        <Typography variant="h3">{announcement.topic}</Typography>
      </Box>
      <DialogContent className={classes.dialogContent}>
        <ReadOnlyEditor message={announcement.message} />
      </DialogContent>
      <DialogActions className={classes.actionButtons}>
        <Button
          variant="text"
          label="Close"
          paddingRight="15px"
          disableElevation
          onClick={() => closeHandler(announcement.id)}
        />
        <Button
          variant="contained"
          color="primary"
          label={t("buttons.read_more")}
          onClick={() => submitHandler(announcement.id)}
        />
      </DialogActions>
    </Dialog>
  );
};

export default AnnouncementDialog;
