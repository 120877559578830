import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    marginTop: "23px",
    marginBottom: "7px",
    [theme.breakpoints.up("md")]: {
      marginTop: "48px",
      marginBottom: "10px",
    },
  },
  heading: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
    },
  },
}));
