import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Popper,
  Button,
  ClickAwayListener,
  Grow,
  Paper,
  MenuList,
  MenuItem,
} from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { useStyles } from "./children.styles";
import Typography from "../../../components/Typography";
import useMe from "../../../utils/use-me";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const classes = useStyles();
  const { me, updateMyAccount } = useMe();
  const [lang, setLang] = useState<string>(
    localStorage.getItem("bhss-lang") || "en"
  );
  const [open, setOpen] = useState<boolean>(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (me) {
      const dbLang = me.language.toLowerCase();
      i18n.changeLanguage(dbLang);
      localStorage.setItem("bhss-lang", dbLang);
      setLang(dbLang);
    }
  }, [me, i18n]);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (e: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(e.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleListKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Tab") {
      e.preventDefault();
      setOpen(false);
    }
  };

  const changeLanguage = async (language: string) => {
    await updateMyAccount({ language: language.toUpperCase() }, () => {});
    await i18n.changeLanguage(language);
    localStorage.setItem("bhss-lang", language);
    setLang(language);
  };

  return (
    <>
      <Button
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
        >
          <Typography customVariant="subtitle3" color="textSecondary">
            {lang.toUpperCase()}
          </Typography>
          <ChevronLeft
            className={`${classes.chevron} ${open && classes.chevronActive}`}
          />
        </Box>
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 1 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem
                    onClick={e => {
                      changeLanguage("en");
                      handleClose(e);
                    }}
                    classes={{ root: classes.langItem }}
                  >
                    <Typography
                      variant="body2"
                      classes={{
                        root: `${classes.language} ${
                          lang === "en" && classes.languageActive
                        }`,
                      }}
                    >
                      English
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={e => {
                      changeLanguage("lt");
                      handleClose(e);
                    }}
                    classes={{ root: classes.langItem }}
                  >
                    <Typography
                      variant="body2"
                      classes={{
                        root: `${classes.language} ${
                          lang === "lt" && classes.languageActive
                        }`,
                      }}
                    >
                      Lietuvių
                    </Typography>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default LanguageSwitcher;
