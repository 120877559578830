import React from "react";
import { IRouteListProps } from "../../types/routeTypes";
import Login from "../../views/Auth/Login";

const Homepage = React.lazy(() => import("../../views/Homepage/Homepage"));
const Dashboard = React.lazy(() => import("../../views/Dashboard/Dashboard"));
const AdministratorsList = React.lazy(
  () => import("../../views/Administrators/AdministratorsList")
);
const AdministratorsCreate = React.lazy(
  () => import("../../views/Administrators/AdministratorsCreate")
);
const AdministratorsEdit = React.lazy(
  () => import("../../views/Administrators/AdministratorsEdit")
);
const AdministratorsView = React.lazy(
  () => import("../../views/Administrators/AdministratorsView")
);
const ClientsList = React.lazy(() => import("../../views/Clients/ClientsList"));
const RulesList = React.lazy(() => import("../../views/Rules/RulesList"));
const RulesCreate = React.lazy(() => import("../../views/Rules/RulesCreate"));
const RulesEdit = React.lazy(() => import("../../views/Rules/RulesEdit"));
const UserAccount = React.lazy(() => import("../../views/Accounts/UserEdit"));
const ContentList = React.lazy(() => import("../../views/Content/ContentList"));
const ContentEdit = React.lazy(() => import("../../views/Content/ContentEdit"));
const EmailEdit = React.lazy(() => import("../../views/Content/EmailEdit"));
const ContentView = React.lazy(() => import("../../views/Content/ContentView"));
const EmailView = React.lazy(() => import("../../views/Content/EmailView"));
const PostLogin = React.lazy(() => import("../../views/Auth/PostLogin"));
const UsersList = React.lazy(() => import("../../views/Users/UsersList"));
const UsersCreate = React.lazy(() => import("../../views/Users/UsersCreate"));
const UsersEdit = React.lazy(() => import("../../views/Users/UsersEdit"));
const AnnouncementsList = React.lazy(
  () => import("../../views/Announcements/AnnouncementsList")
);
const AnnouncementsEdit = React.lazy(
  () => import("../../views/Announcements/AnnouncementsEdit")
);
const AnnouncementsCreate = React.lazy(
  () => import("../../views/Announcements/AnnouncementsCreate")
);
const AnnouncementView = React.lazy(
  () => import("../../views/Announcements/AnnouncementView")
);
const InvoicesList = React.lazy(
  () => import("../../views/Invoices/InvoicesList")
);
const DebtActView = React.lazy(
  () => import("../../views/Invoices/DebtActView")
);
const ContractsList = React.lazy(
  () => import("../../views/MyLease/ContractsList")
);
const CpiView = React.lazy(() => import("../../views/MyLease/CpiView"));
const ReportsView = React.lazy(() => import("../../views/Reports/ReportsView"));
const RequestsList = React.lazy(
  () => import("../../views/Requests/RequestsList")
);
const RequestsView = React.lazy(
  () => import("../../views/Requests/RequestsView")
);
const RequestsCreate = React.lazy(
  () => import("../../views/Requests/RequestsCreate")
);
const PaymentCallback = React.lazy(
  () => import("../../views/Payment/PaymentCallback")
);
const LogsList = React.lazy(() => import("../../views/Logs/LogsList"));

const Turnover = React.lazy(() => import("../../views/MyLease/Turnover"));

const conditionalRoute: IRouteListProps = {
  path: "/devLogin",
  name: "pages.login",
  exact: true,
  component: Login,
  isPrivate: false,
  items: null,
  role: ["root", "bhAdmin", "companyRootAdmin", "companyAdmin"],
  permissions: null,
};

const routes: Array<IRouteListProps> = [
  process.env.REACT_APP_ENVIROMENT === "development" && conditionalRoute,
  {
    path: "/",
    name: "Homepage",
    exact: true,
    component: Homepage,
    isPrivate: true,
    items: null,
    role: ["root", "bhAdmin", "companyRootAdmin", "companyAdmin"],
    permissions: null,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    exact: true,
    component: Dashboard,
    isPrivate: true,
    items: null,
    role: ["companyRootAdmin", "companyAdmin"],
    permissions: null,
  },
  {
    path: "/administrators",
    name: "pages.administrators",
    exact: true,
    component: AdministratorsList,
    isPrivate: true,
    items: null,
    role: ["root", "bhAdmin"],
    permissions: "viewAdmins",
  },
  {
    path: "/administrators/create",
    name: "pages.create_admin",
    exact: true,
    component: AdministratorsCreate,
    isPrivate: true,
    items: null,
    role: ["root", "bhAdmin"],
    permissions: "editAdmins",
  },
  {
    path: "/administrators/edit/:administratorId",
    name: "pages.edit_admin",
    exact: true,
    component: AdministratorsEdit,
    isPrivate: true,
    items: null,
    role: ["root", "bhAdmin"],
    permissions: "editAdmins",
  },
  {
    path: "/administrators/view/:administratorId",
    name: "pages.view_admin",
    exact: true,
    component: AdministratorsView,
    isPrivate: true,
    items: null,
    role: ["root", "bhAdmin"],
    permissions: "viewAdmins",
  },
  {
    path: "/clients",
    name: "pages.clients",
    exact: true,
    component: ClientsList,
    isPrivate: true,
    items: null,
    role: ["root", "bhAdmin"],
    permissions: null,
  },
  {
    path: "/rules",
    name: "pages.rules",
    exact: true,
    component: RulesList,
    isPrivate: true,
    items: null,
    role: ["root", "bhAdmin"],
    permissions: "viewRules",
  },
  {
    path: "/rules/create",
    name: "pages.create_rules",
    exact: true,
    component: RulesCreate,
    isPrivate: true,
    items: null,
    role: ["root", "bhAdmin"],
    permissions: "editRules",
  },
  {
    isPrivate: true,
    path: "/rules/edit/:ruleId",
    name: "pages.edit_rules",
    exact: true,
    component: RulesEdit,
    items: null,
    role: ["root", "bhAdmin"],
    permissions: "editRules",
  },
  {
    isPrivate: true,
    path: "/account",
    name: "pages.account_settings",
    exact: true,
    component: UserAccount,
    items: [
      {
        name: "contact_details",
        path: "/account",
      },
      {
        name: "users",
        path: "/users",
      },
    ],
    role: ["root", "bhAdmin", "companyRootAdmin", "companyAdmin"],
    permissions: null,
  },
  {
    path: "/content",
    name: "pages.content",
    exact: true,
    component: ContentList,
    isPrivate: true,
    items: null,
    role: ["root", "bhAdmin"],
    permissions: "viewContent",
  },
  {
    path: "/content/edit/:type",
    name: "pages.content",
    exact: true,
    component: ContentEdit,
    isPrivate: true,
    items: null,
    role: ["root", "bhAdmin"],
    permissions: "editContent",
  },
  {
    path: "/email/edit/:type",
    name: "pages.content",
    exact: true,
    component: EmailEdit,
    isPrivate: true,
    items: null,
    role: ["root", "bhAdmin"],
    permissions: "editContent",
  },
  {
    path: "/content/view/:type",
    name: "pages.content",
    exact: true,
    component: ContentView,
    isPrivate: true,
    items: null,
    role: ["root", "bhAdmin"],
    permissions: "viewContent",
  },
  {
    path: "/email/view/:type",
    name: "pages.content",
    exact: true,
    component: EmailView,
    isPrivate: true,
    items: null,
    role: ["root", "bhAdmin"],
    permissions: "viewContent",
  },
  {
    path: "/post-login",
    name: "pages.confirm_login",
    exact: true,
    component: PostLogin,
    isPrivate: false,
    items: null,
    role: ["root", "bhAdmin", "companyRootAdmin", "companyAdmin"],
    permissions: null,
  },
  {
    path: "/users",
    name: "pages.account_settings",
    exact: true,
    component: UsersList,
    isPrivate: true,
    items: [
      {
        name: "contact_details",
        path: "/account",
      },
      {
        name: "users",
        path: "/users",
      },
    ],
    role: ["companyRootAdmin"],
    permissions: null,
  },
  {
    path: "/users/create",
    name: "pages.account_settings",
    exact: true,
    component: UsersCreate,
    isPrivate: true,
    items: [
      {
        name: "company_details",
        path: "/account",
      },
      {
        name: "users",
        path: "/users",
      },
    ],
    role: ["companyRootAdmin"],
    permissions: null,
  },
  {
    path: "/users/edit/:userId",
    name: "pages.account_settings",
    exact: true,
    component: UsersEdit,
    isPrivate: true,
    items: [
      {
        name: "company_details",
        path: "/account",
      },
      {
        name: "users",
        path: "/users",
      },
    ],
    role: ["companyRootAdmin"],
    permissions: null,
  },
  {
    path: "/announcements",
    name: "pages.announcements",
    exact: true,
    component: AnnouncementsList,
    isPrivate: true,
    items: null,
    role: ["companyRootAdmin", "companyAdmin", "bhAdmin", "root"],
    permissions: null,
  },
  {
    path: "/announcements/edit/:announcementId",
    name: "pages.draft_announcement",
    exact: true,
    component: AnnouncementsEdit,
    isPrivate: true,
    items: null,
    role: ["companyRootAdmin", "companyAdmin", "bhAdmin", "root"],
    permissions: "editAnnouncements",
  },
  {
    path: "/announcements/create",
    name: "pages.new_announcement",
    exact: true,
    component: AnnouncementsCreate,
    isPrivate: true,
    items: null,
    role: ["companyRootAdmin", "companyAdmin", "bhAdmin", "root"],
    permissions: "editAnnouncements",
  },
  {
    path: "/announcements/:announcementId",
    name: "pages.announcements",
    exact: true,
    component: AnnouncementView,
    isPrivate: true,
    items: null,
    role: ["companyRootAdmin", "companyAdmin", "bhAdmin", "root"],
    permissions: null,
  },
  {
    path: "/invoices",
    name: "pages.invoices",
    exact: true,
    component: InvoicesList,
    isPrivate: true,
    items: [
      {
        name: "all_invoices",
        path: "/invoices",
      },
      {
        name: "debt_act",
        path: "/invoices/debt-act",
      },
    ],
    role: ["companyRootAdmin", "companyAdmin"],
    permissions: null,
  },
  {
    path: "/invoices/debt-act",
    name: "pages.invoices",
    exact: true,
    component: DebtActView,
    isPrivate: true,
    items: [
      {
        name: "all_invoices",
        path: "/invoices",
      },
      {
        name: "debt_act",
        path: "/invoices/debt-act",
      },
    ],
    role: ["companyRootAdmin", "companyAdmin"],
    permissions: null,
  },
  {
    path: "/requests",
    name: "pages.requests",
    exact: true,
    component: RequestsList,
    isPrivate: true,
    items: null,
    role: ["root", "bhAdmin", "companyRootAdmin", "companyAdmin"],
    permissions: null,
  },
  {
    path: "/requests/create",
    name: "pages.requests",
    exact: true,
    component: RequestsCreate,
    isPrivate: true,
    items: null,
    role: ["companyRootAdmin", "companyAdmin"],
    permissions: null,
  },
  {
    path: "/requests/view/:requestId",
    name: "pages.requests",
    exact: true,
    component: RequestsView,
    isPrivate: true,
    items: null,
    role: ["root", "bhAdmin", "companyRootAdmin", "companyAdmin"],
    permissions: null,
  },
  {
    path: "/contracts",
    name: "pages.my_lease",
    exact: true,
    component: ContractsList,
    isPrivate: true,
    items: [
      {
        name: "contracts",
        path: "/contracts",
      },
      //TODO: uncomment when client is ready for rules
      // {
      //   name: "rules",
      //   path: "/contracts/rules",
      // },
      {
        name: "consumer_price_index_cpi",
        path: "/contracts/cpi",
      },
      {
        name: "turnover",
        path: "/contracts/turnover",
      },
    ],
    role: ["companyRootAdmin", "companyAdmin"],
    permissions: null,
  },
  //TODO: uncomment when client is ready for rules
  // {
  //   path: "/contracts/rules",
  //   name: "pages.my_lease",
  //   exact: true,
  //   component: CompanyRulesList,
  //   isPrivate: true,
  //   items: [
  //     {
  //       name: "contracts",
  //       path: "/contracts",
  //     },
  //     {
  //       name: "rules",
  //       path: "/contracts/rules",
  //     },
  //     {
  //       name: "consumer_price_index_cpi",
  //       path: "/contracts/cpi",
  //     },
  //   ],
  //   role: ["companyRootAdmin", "companyAdmin"],
  //   permissions: null,
  // },
  {
    path: "/contracts/cpi",
    name: "pages.my_lease",
    exact: true,
    component: CpiView,
    isPrivate: true,
    items: [
      {
        name: "contracts",
        path: "/contracts",
      },
      //TODO: uncomment when client is ready for rules
      // {
      //   name: "rules",
      //   path: "/contracts/rules",
      // },
      {
        name: "consumer_price_index_cpi",
        path: "/contracts/cpi",
      },
      {
        name: "turnover",
        path: "/contracts/turnover",
      },
    ],
    role: ["companyRootAdmin", "companyAdmin"],
    permissions: null,
  },
  {
    path: "/contracts/turnover",
    name: "pages.my_lease",
    exact: true,
    component: Turnover,
    isPrivate: true,
    items: [
      {
        name: "contracts",
        path: "/contracts",
      },
      //TODO: uncomment when client is ready for rules
      // {
      //   name: "rules",
      //   path: "/contracts/rules",
      // },
      {
        name: "consumer_price_index_cpi",
        path: "/contracts/cpi",
      },
      {
        name: "turnover",
        path: "/contracts/turnover",
      },
    ],
    role: ["companyRootAdmin", "companyAdmin"],
    permissions: null,
  },
  {
    path: "/reports",
    name: "pages.reports",
    exact: true,
    component: ReportsView,
    isPrivate: true,
    items: null,
    role: ["companyRootAdmin", "companyAdmin"],
    permissions: null,
  },
  {
    path: "/payment-callback",
    name: null,
    exact: true,
    component: PaymentCallback,
    isPrivate: false,
    items: null,
    role: ["root", "bhAdmin", "companyRootAdmin", "companyAdmin"],
    permissions: null,
  },
  {
    path: "/logs",
    name: "pages.logs",
    exact: true,
    component: LogsList,
    isPrivate: true,
    items: null,
    role: ["root"],
    permissions: null,
  },
];

export default routes;
