import React, { useState } from "react";
import { NSRouteProps } from "../../types/routeTypes";
import { Box, TextField, Paper, Typography } from "@material-ui/core";
import Button from "../../components/Button";
import { ILoginRequest } from "../../types/authTypes";
import { useAuth } from "../../utils/use-auth";
import Title from "../../components/Title";
import { useTranslation } from "react-i18next";

const Login = (props: NSRouteProps.IRouteProps) => {
  const { name } = props;
  const { login } = useAuth();
  const { t } = useTranslation();

  const [fields, setFields] = useState<ILoginRequest>({
    email: "",
    password: "",
    redirectUrl: process.env.REACT_APP_POST_LOGIN_URL,
  });

  const updateFields = e => {
    if (e.target) {
      setFields({
        ...fields,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    login(fields);
  };

  return (
    <Box
      display="flex"
      width="100%"
      height="90vh"
      alignItems="center"
      justifyContent="center"
    >
      <Paper
        component="form"
        onSubmit={handleSubmit}
        onChange={updateFields}
        elevation={3}
      >
        <Box paddingY={4} paddingX={6} minWidth={350}>
          <Title name={name} />
          <Box marginBottom={2}>
            <Typography>{t("fields.email")}</Typography>
            <TextField
              type="email"
              margin="dense"
              required
              fullWidth
              placeholder={t("placeholders.enter_email")}
              name="email"
              value={fields.email}
              InputProps={{
                autoComplete: "username",
              }}
            />
          </Box>
          <Box marginBottom={2}>
            <Typography>{t("fields.password")}</Typography>
            <TextField
              type="password"
              margin="dense"
              required
              fullWidth
              placeholder={t("placeholders.enter_password")}
              name="password"
              value={fields.password}
              InputProps={{
                autoComplete: "current-password",
              }}
            />
          </Box>
          <Button
            label={t("pages.login")}
            variant="contained"
            color="primary"
            type="submit"
          />
        </Box>
      </Paper>
    </Box>
  );
};

export default Login;
