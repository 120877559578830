import {
  ILoginRequest,
  IRefreshRequest,
  ITokenResponse,
  IAuthContext,
} from "../types/authTypes";
import React, {
  useState,
  useContext,
  createContext,
  useEffect,
  useMemo,
} from "react";
import {
  hasAuthHeader,
  mutateData,
  setAuthHeader,
  setCompanyIdHeader,
} from "./api";
import { parse } from "query-string";
import { useLocation, useHistory } from "react-router-dom";
import { mutate } from "swr";
import axios from "axios";
import useNotifications from "./use-notifications";
import { useTranslation } from "react-i18next";

const authContext = createContext<IAuthContext>({
  accessToken: "",
  refreshToken: "",
  login: () => {},
  getTokens: () => {},
  logout: () => {},
  refresh: () => {},
  isLoggedIn: false,
  impersonate: () => {},
  isImpersonating: false,
  stopImpersonating: () => {},
  impersonationDone: false,
});

export const ProvideAuth = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
  return useContext(authContext);
};

const useProvideAuth = () => {
  const { showError } = useNotifications();
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const {
    i18n: { language },
  } = useTranslation();
  const storedTokens = localStorage.getItem("tokens");
  const initialTokens = storedTokens ? JSON.parse(storedTokens) : null;
  const [tokens, setTokens] = useState<ITokenResponse | null>(initialTokens);
  const [isImpersonating, setIsImpersonating] = useState<boolean>(false);
  const [impersonationDone, setImpersonationDone] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const isLoggedIn = useMemo(() => {
    const isValidRoute = location.pathname !== "/post-login";
    return tokens !== null && initialTokens && hasAuthHeader() && isValidRoute;
  }, [tokens, initialTokens, location]);

  useEffect(() => {
    if (!isLoggedIn) {
      setIsImpersonating(false);
    }
  }, [isLoggedIn]);

  const impersonate = (companyId: string) => {
    mutateData("post", `/companies/${companyId}/impersonate`)
      .then(res => {
        const tokens = res.data;
        setTokens(tokens);
        setAuthHeader(res.data.accessToken);
        setCompanyIdHeader(companyId);
        setIsImpersonating(true);
        setImpersonationDone(true);
        history.push("/");
      })
      .catch(error => {
        if (error.response && error.response.data) {
          const errorData = error.response.data;
          const message = errorData.subCode
            ? t(`error.${errorData.subCode}`)
            : errorData.message;
          showError(t("error.something_went_wrong"), message);
        } else {
          showError(t("error.something_went_wrong"), "");
        }
      });
  };

  const stopImpersonating = () => {
    setTokens(null);
    if (initialTokens) {
      setAuthHeader(initialTokens.accessToken);
      setTokens(initialTokens);
    }
    setIsImpersonating(false);
  };

  const login = (fields: ILoginRequest) => {
    mutateData("post", "/auth/login", fields)
      .then(res => {
        window.location.href = res.data.redirectUrl;
      })
      .catch(error => {
        if (error.response && error.response.data) {
          const errorData = error.response.data;
          const message = errorData.subCode
            ? t(`error.${errorData.subCode}`)
            : errorData.message;
          showError(t("error.something_went_wrong"), message);
        } else {
          showError(t("error.something_went_wrong"), "");
        }
      });
  };

  const getTokens = () => {
    const params = parse(location.search);
    if ("authRequestId" in params) {
      const requestData: IRefreshRequest = {
        authRequestId: params.authRequestId,
      };
      mutateData("post", "/auth/acquireTokens", requestData)
        .then((res: any) => {
          localStorage.setItem("tokens", JSON.stringify(res.data));
          setTokens(res.data);
          setAuthHeader(res.data.accessToken);
        })
        .then(() => {
          history.push("/");
        })
        .catch(error => {
          throw new Error(error.message);
        });
    } else {
      window.location.replace(
        `${process.env.REACT_APP_BALTHAUS_URL}${
          language === "en" ? "/en/login" : "/prisijunkite"
        }`
      );
    }
  };

  const logout = () => {
    setIsImpersonating(false);
    mutateData("delete", "/auth/logout")
      .then((res: any) => {
        if (res.status === 200) {
          window.location.replace(
            `${process.env.REACT_APP_BALTHAUS_URL}${
              language === "en" ? "/en/login" : "/prisijunkite"
            }`
          );
          localStorage.removeItem("tokens");
          setTokens(null);
          setAuthHeader("");
        }
      })
      .catch(error => {
        throw new Error(error.message);
      });
  };

  const refresh = async () => {
    if (tokens !== null && !isRefreshing) {
      setIsRefreshing(true);
      mutateData("put", "/auth/refresh", { refreshToken: tokens.refreshToken })
        .then((res: any) => {
          setIsRefreshing(false);
          if (res.status === 200) {
            localStorage.setItem("tokens", JSON.stringify(res.data));
            setTokens(res.data);
            return null;
          } else {
            setTokens(null);
            localStorage.removeItem("tokens");
            history.push("login");
            return null;
          }
        })
        .catch(error => {
          setIsRefreshing(false);
          if (error) {
            setTokens(null);
            localStorage.removeItem("tokens");
            history.push("login");
            return null;
          }
        });
    }
  };

  useEffect(() => {
    axios.interceptors.request.use(
      async config => {
        if (tokens !== null && !axios.defaults.headers.common["Authorization"])
          setAuthHeader(tokens.accessToken);
        return config;
      },
      error => {
        Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      response => {
        return response;
      },
      async function (error) {
        const originalRequest = error.config;
        const status = error.response.status;
        if (status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          await refresh();
          setAuthHeader(tokens.accessToken);
          return axios(originalRequest);
        }
        return Promise.reject(error);
      }
    );
    mutate("/users/me");
    //eslint-disable-next-line
  }, [tokens]);

  return {
    ...tokens,
    login,
    getTokens,
    logout,
    refresh,
    isLoggedIn,
    impersonate,
    stopImpersonating,
    isImpersonating,
    impersonationDone,
  };
};
