import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "../Routes/Routes";
import { I18nextProvider } from "react-i18next";
import i18n from "../../i18n";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "../../styles/theme";
import Header from "../Header/Header";
import { CssBaseline } from "@material-ui/core";
import { SWRConfig } from "swr";
import { SWROptions } from "../../utils/api";
import ErrorBoundary from "../ErrorBoundary";
import { ProvideAuth } from "../../utils/use-auth";
import { ProvideMe } from "../../utils/use-me";
import UnreadAnnouncements from "../UnreadAnnouncements/UnreadAnnouncements";
import StopImpersonatingButton from "../../components/StopImpersonatingButton";
import CpiChanges from "../CpiChanges/CpiChanges";
import NotificationContainer from "../NotificationContainer/NotificationContainer";
import { ProvideNotifications } from "../../utils/use-notifications";
import AppContainer from "../AppContainer/AppContainer";
import ConfirmProfile from "../ConfirmProfile/ConfirmProfile";
import CookiesPopup from "../../components/CookiesPopup";

const App = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={theme}>
        <SWRConfig value={SWROptions}>
          <CssBaseline />
          <Router>
            <ErrorBoundary>
              <ProvideNotifications>
                <ProvideAuth>
                  <ProvideMe>
                    <Header />
                    <AppContainer>
                      <ConfirmProfile />
                      <UnreadAnnouncements />
                      <CpiChanges />
                      <NotificationContainer />
                      <CookiesPopup />
                      <Routes />
                    </AppContainer>
                    <StopImpersonatingButton />
                  </ProvideMe>
                </ProvideAuth>
              </ProvideNotifications>
            </ErrorBoundary>
          </Router>
        </SWRConfig>
      </ThemeProvider>
    </I18nextProvider>
  );
};

export default App;
