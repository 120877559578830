import {
  Editor,
  Text,
  Transforms,
  Range,
  Element as SlateElement,
} from "slate";
import escapeHtml from "escape-html";
import { jsx } from "slate-hyperscript";

const LIST_TYPES = ["numbered-list", "bulleted-list"];

const useSlateMethods = () => {
  const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format);

    if (isActive) {
      Editor.removeMark(editor, format);
    } else {
      Editor.addMark(editor, format, true);
    }
  };

  const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
  };

  const toggleBlock = (editor, format) => {
    const isActive = isBlockActive(editor, format);
    const isList = LIST_TYPES.includes(format);

    Transforms.unwrapNodes(editor, {
      match: n => LIST_TYPES.includes(n.type as string),
      split: true,
    });

    Transforms.setNodes(editor, {
      type: isActive ? "paragraph" : isList ? "list-item" : format,
    });

    if (!isActive && isList) {
      const block = { type: format, children: [] };
      Transforms.wrapNodes(editor, block);
    }
  };

  const isBlockActive = (editor, format) => {
    const [match] = Editor.nodes(editor, {
      match: n => n.type === format,
    });

    return !!match;
  };

  const serialize = nodes => {
    return nodes.map(node => serializeChild(node)).join("\n");
  };

  const serializeChild = node => {
    if (Text.isText(node)) {
      return styling(node);
    }
    const children =
      node &&
      node.children &&
      node.children.map(n => serializeChild(n)).join("");

    const color = node.color ? `color: ${node.color}` : null;
    const size =
      typeof node.fontSize === "string"
        ? Number(node.fontSize.replace(/[^0-9]/g, ""))
        : node.fontSize;
    const fontSize = node.fontSize ? `font-size: ${size}px` : null;
    const style =
      color && fontSize
        ? ` style="${color}; ${fontSize}"`
        : color
        ? ` style="${color}"`
        : fontSize
        ? ` style="${fontSize}"`
        : "";

    switch (node.type) {
      case "block-quote":
        return `<blockquote${style}>${children}</blockquote>`;
      case "paragraph":
        return `<p${style}>${children}</p>`;
      case "heading-one":
        return `<h1>${children}</h1>`;
      case "heading-two":
        return `<h2>${children}</h2>`;
      case "list-item":
        return `<li${style}>${children}</li>`;
      case "numbered-list":
        return `<ol${style}>${children}</ol>`;
      case "bulleted-list":
        return `<ul${style}>${children}</ul>`;
      case "link":
        return `<table style="width: fit-content;"><tbody><tr><td style=" padding: 6px 25px; box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); font-family: 'DM Sans', 'Open Sans', sans-serif; font-weight: 500; border-radius: 2px; background-color: #4646b4; margin: 5px 0; text-align: center; color: white; height: 38px; " ><a style="text-decoration: none; color: white; font-size: 16px" rel="noreferrer" target="_blank" href="${escapeHtml(
          node.url
        )}" >${children}</a></td></tr></tbody></table>`;
      case "image":
        return `<img alt="Uploaded" src="${escapeHtml(
          node.url
        )}" style="max-height: 20em;max-width: 100%;display: block;" />`;
      default:
        return `<p${style}>${children}</p>`;
    }
  };

  const styling = leaf => {
    const color = leaf.color ? `color: ${leaf.color}` : null;
    const size =
      typeof leaf.fontSize === "string"
        ? Number(leaf.fontSize.replace(/[^0-9]/g, ""))
        : leaf.fontSize;
    const fontSize = leaf.fontSize ? `font-size: ${size}px` : null;
    const style =
      color && fontSize
        ? ` style="${color}; ${fontSize}"`
        : color
        ? ` style="${color}"`
        : fontSize
        ? ` style="${fontSize}"`
        : "";
    let children = leaf.text;

    if (leaf.bold) {
      children = `<strong>${children}</strong>`;
    }
    if (leaf.code) {
      children = `<code>${children}</code>`;
    }
    if (leaf.italic) {
      children = `<em>${children}</em>`;
    }
    if (leaf.underline) {
      children = `<u>${children}</u>`;
    }
    if (leaf.color) {
      children = `<span${style}>${children}</span>`;
    }
    if (leaf.fontSize) {
      children = `<span${style}>${children}</span>`;
    }
    return children;
  };

  const deserialize = el => {
    if (el.nodeType === 3) {
      return el.textContent;
    } else if (el.nodeType !== 1) {
      return null;
    }

    let children = Array.from(el.childNodes).map(deserialize);
    if (children.length < 1) {
      children = { text: el.textContent };
    }

    const bold = !!(
      children.length > 0 &&
      (el.nodeName === "STRONG" || children[0]["bold"] === true)
    );
    const underline = !!(
      children.length > 0 &&
      (el.nodeName === "U" || children[0]["underline"] === true)
    );
    const italic = !!(
      children.length > 0 &&
      (el.nodeName === "EM" || children[0]["italic"] === true)
    );
    const code = !!(
      children.length > 0 &&
      (el.nodeName === "CODE" || children[0]["code"] === true)
    );

    switch (el.nodeName) {
      case "BODY":
        if (children[0] === undefined) {
          children = jsx("element", { type: "paragraph" }, children);
        } else if (children[0].type === undefined) {
          children[0] = jsx("element", { type: "paragraph" }, children[0]);
        }
        return jsx("fragment", {}, children);
      case "BR":
        return "\n";
      case "BLOCKQUOTE":
        return jsx(
          "element",
          {
            type: "block-quote",
            color: el.style.color,
            fontSize: el.style.fontSize,
          },
          children
        );
      case "P":
        return jsx(
          "element",
          {
            type: "paragraph",
            color: el.style.color,
            fontSize: el.style.fontSize,
          },
          children
        );
      case "LI":
        return jsx(
          "element",
          {
            type: "list-item",
            color: el.style.color,
            fontSize: el.style.fontSize,
          },
          children
        );
      case "OL":
        return jsx(
          "element",
          {
            type: "numbered-list",
            color: el.style.color,
            fontSize: el.style.fontSize,
          },
          children
        );
      case "UL":
        return jsx(
          "element",
          {
            type: "bulleted-list",
            color: el.style.color,
            fontSize: el.style.fontSize,
          },
          children
        );
      case "H1":
        return jsx("element", { type: "heading-one" }, children);
      case "H2":
        return jsx("element", { type: "heading-two" }, children);
      case "TABLE":
        return jsx(
          "element",
          {
            type: "link",
            url: el.getElementsByTagName("a")[0].getAttribute("href"),
          },
          children
        );
      case "IMG":
        return jsx(
          "element",
          { type: "image", url: el.getAttribute("src") },
          children
        );
      default:
        return {
          text: el.textContent,
          bold,
          underline,
          italic,
          code,
          color: el.style.color,
          fontSize: el.style.fontSize,
        };
    }
  };

  const parseHtmlToDoc = content => {
    const document = new DOMParser().parseFromString(
      content.replace(/\n|\r/g, ""),
      "text/html"
    );
    return document.body;
  };

  const wrapLink = (editor, url) => {
    if (isLinkActive(editor)) {
      unwrapLink(editor);
    }

    const { selection } = editor;
    const isCollapsed = selection && Range.isCollapsed(selection);
    const link = {
      type: "link",
      url,
      children: isCollapsed ? [{ text: url }] : [],
    };

    if (isCollapsed) {
      Transforms.insertNodes(editor, link);
    } else {
      Transforms.wrapNodes(editor, link, { split: true });
      Transforms.collapse(editor, { edge: "end" });
    }
  };

  const isLinkActive = editor => {
    const [link] = Editor.nodes(editor, {
      match: n =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link",
    });
    return !!link;
  };

  const unwrapLink = editor => {
    Transforms.unwrapNodes(editor, {
      match: n =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link",
    });
  };

  const insertLink = (editor, url) => {
    if (editor.selection) {
      wrapLink(editor, url);
    }
  };

  const insertImage = (editor, url) => {
    const text = { text: "" };
    const image = { type: "image", url, children: [text] };
    Transforms.insertNodes(editor, image);
  };

  const addFontSize = (editor, value: number) => {
    const isListItem = isBlockActive(editor, "list-item");
    const format = "fontSize";
    if (isListItem) {
      Transforms.setNodes(
        editor,
        { type: "list-item", fontSize: value },
        { match: n => Editor.isBlock(editor, n) }
      );
      Editor.addMark(editor, format, value);
    } else {
      Editor.addMark(editor, format, value);
    }
  };

  const addColor = (editor, value: string) => {
    const format = "color";
    const isListItem = isBlockActive(editor, "list-item");

    if (isListItem) {
      Transforms.setNodes(
        editor,
        { type: "list-item", color: value },
        { match: n => Editor.isBlock(editor, n) }
      );
      Editor.addMark(editor, format, value);
    } else {
      Editor.addMark(editor, format, value);
    }
  };

  const HOTKEYS = {
    "mod+b": "bold",
    "mod+i": "italic",
    "mod+u": "underline",
    "mod+`": "code",
  };

  const initialValue = [
    {
      type: "paragraph",
      children: [{ text: "" }],
    },
  ];

  return {
    toggleMark,
    toggleBlock,
    HOTKEYS,
    isMarkActive,
    isBlockActive,
    serialize,
    deserialize,
    initialValue,
    parseHtmlToDoc,
    wrapLink,
    isLinkActive,
    insertLink,
    insertImage,
    addFontSize,
    addColor,
  };
};

export default useSlateMethods;
